import React from 'react';

import './progress-circle.scss';

export interface Props {
  /**
   * ID needed to make sure the circle is given the right colors
   */
  id: string;
  /**
   * Radius of the circle in px
   */
  radius: number;
  /**
   * Circle troke width in px
   */
  stroke: number;
  /**
   * Progress made in % (min 0, max 100)
   */
  progress: number;
  /**
   * Colors of the circle stroke. Passing in multiple colors will create a linear gradient.
   */
  colors: string[];
  /**
   * Bold text displayed inside the progress circle
   */
  primaryText?: string;
  /**
   * Text displayed below the primaryText
   */
  secondaryText?: string;
  /**
   * Override primaryText font size (include unit). The default font size is based on the stroke property
   */
  primaryFontSize?: string;
  /**
   * Override secondaryText font size (include unit). The default font size is based on the stroke property
   */
  secondaryFontSize?: string;
  /**
   * Set the track circles color. Defaults to #DDD
   */
  trackColor?: string;
}

export const ProgressCircle: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    window.setTimeout(() => setProgress(Math.min(Math.max(Math.floor(props.progress), 0), 100)), 0);
  }, [props.progress]);

  const normalizedRadius = props.radius - (props.stroke * 0.5);
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;

  return (
    <div className="progress-circle">
      <div className="wrapper">
        <div className="main-text" style={{
          width: (props.radius * 2) - (props.stroke * 4),
          left: props.stroke * 2,
          top: props.stroke * 2.5,
          fontSize: props.primaryFontSize || props.stroke * 1.5
        }}>
          {props.primaryText}
        </div>
        <div className="secondary-text" style={{
          width: (props.radius * 2) - (props.stroke * 4),
          left: props.stroke * 2,
          top: props.radius - (props.stroke * 0.5),
          fontSize: props.secondaryFontSize || props.stroke
        }}>
          {props.secondaryText}
        </div>
        <svg
          className="progress-circle__svg"
          height={props.radius * 2}
          width={props.radius * 2}
        >
          <linearGradient id={`gradient-${props.id}`} x1="0%" y1="0%" x2="0%" y2="100%">
            {props.colors.reverse().map((color, i) => (
              <stop key={`${color}-${i}`} offset={`${100 / (props.colors.length - 1) * i}%`} stopColor={color} />
            ))}
          </linearGradient>
          <circle
            stroke={props.trackColor || "#DDD"}
            fill="transparent"
            strokeWidth={ props.stroke }
            r={ normalizedRadius }
            cx={ props.radius }
            cy={ props.radius }
          />
          <circle
            stroke={`url(#gradient-${props.id})`}
            strokeLinecap="round"
            fill="transparent"
            strokeWidth={ props.stroke }
            strokeDasharray={`${circumference} ${circumference}`}
            style={ { strokeDashoffset } }
            r={ normalizedRadius }
            cx={ props.radius }
            cy={ props.radius }
          />
        </svg>
      </div>
    </div>
  );
}