import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Topbar } from './topbar/Topbar';

interface Props extends RouteProps {
  navRef: React.Ref<typeof Topbar>;
}

export const LXPRoute: React.FC<Props> = (props) => {
  return (
    <Route {...props}>
      <>
        <Topbar ref={props.navRef}/>
        <div>
          {props.children}
        </div>
      </>
    </Route>
  );
}