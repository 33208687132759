import React from 'react';

import './progress-bar.scss';

export interface Props {
  /**
   * Progress made
   */
  progress: number;
  /**
   * Maximum progress can reach
   */
  max: number;
  /**
   * Color the progress bar is displayed in
   */
  color: string;
  text?: string;
  percentage?: boolean;
  type?: string;
}

export const ProgressBar: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    window.setTimeout(() => setProgress(Math.min(Math.max(Math.floor(props.progress), 0), props.max)), 0);
  }, [props.progress, props.max]);

  return (
    <div className="progress-bar">
      <div className="progress-bar__text">
        {!props.percentage 
          ? <span>{props.progress} <b>/ {props.max}</b> {props.text}</span>
          : <span><b>{props.progress}%</b> {props.text}</span>
        }
      </div>
      <div className="progress-bar__bar">
        <div className="progress-bar__value" style={{backgroundColor: props.color, width: `${progress / props.max * 100}%`}}/>
      </div>
    </div>
  );
}