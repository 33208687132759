import moment from 'moment';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { EventCalendar } from '../../components/event-calendar/EventCalendar';
import { FAIcon } from '../../components/fa-icon/FAIcon';
import { LessonCard } from '../../components/lesson-card/LessonCard';
// import { ProgressBar } from '../../components/progress-bar/ProgressBar';
import { ProgressCircle } from '../../components/progress-circle/ProgressCircle';
import { StackedProgressCircle } from '../../components/stacked-progress-circle/StackedProgressCircle';
import { Mission, TEST_HOME_SCREEN_DATA, TEST_MISSIONS, TEST_USERS } from '../test-data';
import { Topbar } from '../topbar/Topbar';

import './home-old.scss';

interface Props {
  nav: React.Ref<typeof Topbar>;
}

const MISSION_ENGINE = require('../../img/recyclist.jpg').default;

export const Home_Old: React.FC<Props> = (props) => {
  const [lessons, setLessons] = React.useState<Mission[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  React.useEffect(() => {
    const missions = TEST_MISSIONS.filter(mission => TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id))
    fillLessonList(missions);
  }, []);

  const fillLessonList = (missions: Mission[], index: number = 1) => {
    let tmp = [];
    for (let i = 0; i < index; i++) {
      tmp.push(missions[i]);
    }

    setLessons(tmp);
    if (index < missions.length) {
      window.setTimeout(() => fillLessonList(missions, index + 1), 75);
    }
  }

  const user = TEST_USERS.find(user => user.id === Number(localStorage.getItem('userId')));
  const {recommendation, calendarEvents, analytics, profileOverview } = TEST_HOME_SCREEN_DATA;

  return (
    <section className="home">
      <div className="left">
        <div className="section">
          <h1><b>{user?.firstName}</b>, this might interest you</h1>
          <div className="mission-details__wrapper">
            <img className="mission-details__wrapper--visual" src={MISSION_ENGINE} alt="mission"/>
            <div className="mission-details__wrapper--title">
              {recommendation.profileName}
            </div>
            <div className="mission-details__wrapper--text">
              {recommendation.profileDesc}
            </div>
          </div>
          <div className="mission-details__credits">
            <div>Matches {recommendation.matchedSkills} of your skills</div>
            <div><FAIcon name="eye"/> {recommendation.views}</div>
            <div>{recommendation.skillsToGo} to go to complete this profile</div>
            <div><FAIcon name="clock" /> 6h</div>
            {/* <div><ProgressBar color="#007bff" progress={Math.round(recommendation.skillsToGo / ((recommendation.skillsToGo + recommendation.matchedSkills) / 100))}/></div> */}
          </div>
        </div>
        <div className="section">
          <h1 className="next-up"><b>Next up</b>, {moment(selectedDate).format('DD. MMMM YYYY')}</h1>
          <EventCalendar onChange={setSelectedDate} events={calendarEvents}/>
        </div>
      </div>
      <div className="right">
        <h1>Your Learning Analytics</h1>x
        <div className="progress-overview">
          {analytics.map(analytic => (
              <ProgressCircle id={analytic.id} stroke={20} radius={100} progress={analytic.progress} colors={analytic.colors} primaryText={analytic.primaryText} secondaryText={analytic.secondaryText}/>
          ))}
        </div>
        <h1>Logistiker Overview</h1>
        <div className="profile-overview">
          <div className="breakdown">
          <div>Profile Score: {Math.round(profileOverview.map(o => o.progress).reduce((curr, acc) => curr + acc, 0) / profileOverview.length)}%</div>
            {profileOverview.map(o => (
              <BulletItem text={o.name} color={o.color} />
            ))}
          </div>
          <div className="graphic">
            <StackedProgressCircle stroke={30} radius={90} missions={profileOverview}/>
          </div>
        </div>
        <div className="profile-lessons">
          <TransitionGroup>
            {lessons.map((lesson) => (
              <CSSTransition key={lesson.id} timeout={600} classNames="lesson-card">
                <LessonCard nav={props.nav} linkTo={`/mission/${lesson.id}`} title={lesson.name} author={`${lesson.author.firstName} ${lesson.author.lastName}`} profile={lesson.profile} img={lesson.img} timestamp={lesson.lastVisited}/>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
    </section>
  );
}

interface BulletItemProps {
  color: string;
  text: string;
}

const BulletItem: React.FC<BulletItemProps> = (props) => {
  return (
    <div className="bullet-item">
      <span className="circle" style={{backgroundColor: props.color}}/>
      <label>{props.text}</label>
    </div>
  );
}
