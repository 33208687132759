import { TEST_MISSIONS } from "../app/test-data";

export const countUp = (setter: (value: number) => void, endValue: number, currentValue: number = 0, speed = 10) => {
  setter(currentValue);
  
  if (currentValue < endValue) {
    window.setTimeout(() => countUp(setter, endValue, currentValue + 1, speed), speed);
  }
}

export const getTimeString = (date: Date) => {
  const secondsSincePost = (new Date().getTime() / 1000) - (date.getTime() / 1000);
  let timeString = date.toLocaleDateString();

  if (secondsSincePost < 60) timeString = `${Math.floor(secondsSincePost)}s`;
  else if (secondsSincePost < 3600) timeString = `${Math.floor(secondsSincePost / 60)}m`;
  else if (secondsSincePost < 86400) timeString = `${Math.floor(secondsSincePost / 60 / 60)}h`;
  else if (secondsSincePost < 604800) timeString = `${Math.floor(secondsSincePost / 60 / 60 / 24)}d`;
 
  return timeString
}

export const calcMissionScore = (missionId: number) => {
  const mission = TEST_MISSIONS.find(mission => mission.id === missionId);
  
  if (mission) {
    if (mission.lessons.some(lesson => lesson.score || lesson.score === 0)) {
      let accumulation = 0;
      let numberOfTests = 0;

      mission.lessons.forEach(lesson => {
        if (lesson.score || lesson.score === 0) {
          accumulation += lesson.score;
          numberOfTests++;
        }
      });

      return Math.round(accumulation / numberOfTests);
    }
    else {
      let completedLessons = mission.lessons.filter(lesson => lesson.completed).length;

      return Math.round(100 / mission.lessons.length * completedLessons);
    }
  }

  return 0;
}