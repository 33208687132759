import React from 'react';
import ReactDOM from 'react-dom';
import { LXPRouter } from './app/LXPRouter';

import './index.scss';

ReactDOM.render(  
  <React.StrictMode>
    <LXPRouter />
  </React.StrictMode>,
  document.getElementById('root')
);
