import React from 'react';
import moment from 'moment';
import { Calendar } from '../calendar/Calendar';

import './event-calendar.scss';

export interface Props {
  events: Event[];
  onChange?: (datte: Date | null) => void;
}

export interface Event {
  id: number;
  date: Date;
  content: string | JSX.Element; 
}

export const EventCalendar: React.FC<Props> = (props) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());
  
  React.useEffect(() => {
    if (props.onChange) props.onChange(selectedDate);
  }, [selectedDate]);

  const events = props.events.filter((event) => moment(event.date).isSame(selectedDate, 'day'));

  return (
    <div className="event-calendar">
      <ul className="event-list" style={{height: `${45 * events.length}px`}}>
        {events.map((event) => (
          <>
            <li key={`${event.date.toString()}-${event.content}`} className="event-list__item">
              {event.content}
              <div className="event-list__time">{moment(event.date).format('kk:mm')}</div>
            </li>
          </>
        ))}
      </ul>
      <Calendar
        initialDate={new Date()}
        onChange={setSelectedDate}
      />
    </div>
  );
}