import {Event} from "../components/event-calendar/EventCalendar";
import { calcMissionScore } from "../components/helpers";
import {ProgressMission} from "../components/stacked-progress-circle/StackedProgressCircle";
import {StoreCategory} from "./explore/Explore";

export const TEST_MISSION_IMG = require('../img/html.jpg').default;
export const BUDGET_IMG = require('../img/budget.png').default;
export const TEST_AVATAR_IMG = require('../img/test_avatar.png').default;
export const DENISE_IMG = require('../img/denise.png').default;
export const KAUFVERTRAG_IMG = require('../img/kaufvertrag.jpg').default;
export const BARGELDLOS_IMG = require('../img/bargeldlos.jpg').default;
export const RECHTE_IMG = require('../img/rechte.jpg').default;
export const ALGEBRA_IMG = require('../img/algebra.jpg').default;
export const HISTORY_IMG = require('../img/history.jpeg').default;

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    class: string;
    img: string;
    followers: number;
    courses: number;
    booked: number[];
}

export const TEST_USERS: User[] = [
    {
        id: 1,
        firstName: "Denise",
        lastName: "Merz",
        followers: 1212,
        courses: 8,
        img: DENISE_IMG,
        booked: [1, 2, 3, 4, 5, 6,7,10],
        class: 'IA19B'
    },
    {
        id: 2,
        firstName: "Denise",
        lastName: "Merz",
        followers: 2452,
        courses: 7,
        img: DENISE_IMG,
        booked: [],
        class: 'IA19B'
    },
    {
        id: 3,
        firstName: "Ramon",
        lastName: "Lisibach",
        followers: 2,
        courses: 2,
        img: TEST_AVATAR_IMG,
        booked: [],
        class: 'II20A'
    },
    {
        id: 4,
        firstName: "Audrey",
        lastName: "Harrison",
        followers: 46,
        courses: 2,
        img: TEST_AVATAR_IMG,
        booked: [],
        class: 'II20B'
    },
    {
        id: 5,
        firstName: "Elenaor",
        lastName: "Simpson",
        followers: 23754,
        courses: 54,
        img: TEST_AVATAR_IMG,
        booked: [],
        class: 'IS21A'
    },
]

export interface Message {
    from: User;
    to: User;
    content: string;
    timestamp: Date;
    seen: boolean;
}

export const TEST_MESSAGES: Message[] = [
    {
        to: TEST_USERS[0],
        from: TEST_USERS[1],
        content: "Hi, what's up?",
        timestamp: new Date("2021-01-12T10:00:00"),
        seen: true
    },
    {
        to: TEST_USERS[1],
        from: TEST_USERS[0],
        content: "Hi Brittany, I'm good thanks :)",
        timestamp: new Date("2021-01-12T10:04:00"),
        seen: true
    },
    {
        to: TEST_USERS[1],
        from: TEST_USERS[0],
        content: "I have been very focused on this programming course which I'm taking as part of my software engineering profile, but  I'm kind of stuck on a complex problem...",
        timestamp: new Date("2021-01-12T10:05:00"),
        seen: true
    },
    {
        to: TEST_USERS[0],
        from: TEST_USERS[1],
        content: "Call me quickly, I'll help you out :)",
        timestamp: new Date("2021-01-12T10:07:00"),
        seen: false
    },
    {
        to: TEST_USERS[0],
        from: TEST_USERS[2],
        content: "Hi Rodney, are you already done with your homework?",
        timestamp: new Date("2021-01-11T16:00:00"),
        seen: true
    },
    {
        to: TEST_USERS[2],
        from: TEST_USERS[0],
        content: "Hi, sorry I'm only getting back to you so late. I haven't really checked my messages yesterday... But yes, I was already done.",
        timestamp: new Date("2021-01-12T09:12:00"),
        seen: true
    },
]

export interface Skill {
    id: number;
    name: string;
    color: string;
    score?: number; // leave empty, will be automatically calculated based on missions associated with this skill
    learnings: string[];
}

export const TEST_SKILLS: { [k: string]: Skill } = {
    webDevelopment: {
        id: 1,
        name: "Web Development",
        color: "red",
        learnings: ['Learning 1', 'Learning 2', 'Learning 3', 'Learning 4']
    },
    engine: {id: 2, name: "Engine", color: "green", learnings: []},
    german: {id: 3, name: "German", color: "blue", learnings: []},
    logistic: {id: 3, name: "Logistik", color: "red", learnings: []},
    electricBoards: {id: 4, name: "Electric Boards", color: "purple", learnings: []},
    taxAndLaw: {id: 5, name: "Tax & Law", color: "yellow", learnings: []},
    mathematics: {
        id: 6,
        name: "Mathematics",
        color: "cyan",
        learnings: ['Learning 1', 'Learning 2', 'Learning 3', 'Learning 4']
    },
    neuerLebensabschnitt: {
        id: 7,
        name: "Neuer Lebensabschnitt",
        color: "cyan",
        learnings: [
            'Sie können ein Anliegen gegenüber einer öffentlichen Stelle (Gemeinde, Schule) schriftlich begründet formulieren. ',
            'Sie können sich mit Regeln in einer Gemeinschaft auseinandersetzen, dementsprechend Handeln und sind sich auch den Konsequenzen bei Nichteinhaltung bewusst.']
    },
    geldUndKonsum: {
        id: 8,
        name: "Geld und Konsum",
        color: "red",
        learnings: [
            'Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen.',
            'Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen']
    },
    risk: {
        id: 9,
        name: "Risiko und Sicherheit",
        color: "pink",
        learnings: [
            'Sie können Situationen hinsichtlich ihrer Risiken einschätzen und analysieren.']
    }, health: {
        id: 10,
        name: "Gesundheit",
        color: "yellow",
        learnings: [
            'Sie können Grenzen setzen (Abgrenzung im Dreierschritt) und diese Methode in einer konkreten Situation anwenden.']
    },
    politics: {
        id: 11,
        name: "Politik",
        color: "black",
        learnings: [
            'Sie verstehen die wichtigsten Merkmale der Staatsform der Schweiz und können Sie mit einem anderen Land vergleichen.']
    },
    together: {
        id: 12,
        name: "Zusammenleben",
        color: "blue",
        learnings: [
            'Sie verstehen die wichtigsten Merkmale der Staatsform der Schweiz und können Sie mit einem anderen Land vergleichen.']
    },
    history: {
        id: 13,
        name: "Geschichte",
        color: "darkgreen",
        learnings: [
            'Sie verstehen die wichtigsten Merkmale der Staatsform der Schweiz und können Sie mit einem anderen Land vergleichen.']
    },
}

export interface Competence {
    id: number;
    name: string;
    color: string;
    skills: Skill[];
}

export const TEST_COMPETENCES: Competence[] = [
    {
        id: 1,
        name: 'ABU',
        color: 'red',
        skills: [
            TEST_SKILLS.geldUndKonsum,
            TEST_SKILLS.history,
            TEST_SKILLS.neuerLebensabschnitt,
            TEST_SKILLS.risk,
            TEST_SKILLS.health,
            TEST_SKILLS.politics,
            TEST_SKILLS.together,
        ]
    },
    {id: 2, name: 'Logistik', color: 'blue', skills: [TEST_SKILLS.logistic, TEST_SKILLS.german]},
    {id: 3, name: 'ÜK', color: 'green', skills: [TEST_SKILLS.engine, TEST_SKILLS.electricBoards]},
]

export enum Level {
    Basic = 1,
    Advanced = 2,
    Professional = 3,
}

export interface Lesson {
    id: number;
    number: string;
    name: string;
    duration: string;
    locked: boolean;
    lessonLink: string;
    score?: number;
    completed?: boolean;
}

export interface Mission {
    id: number;
    name: string;
    skill: Skill;
    category: StoreCategory;
    img: string;
    author: User;
    lastVisited: Date;
    profile: string;
    credits: number;
    score?: number; // leave empty, will be automatically calculated based on lessons associated with this mission
    language: string;
    duration: string;
    views: string;
    competences: string[];
    description: string;
    lessons: Lesson[];
    level: Level;
}

export const TEST_MISSIONS: Mission[] = [
    {
        id: 1,
        name: "Kaufvertrag",
        level: Level.Professional,
        skill: TEST_SKILLS.geldUndKonsum,
        category: StoreCategory.SoftwareDevelopment,
        img: KAUFVERTRAG_IMG,
        author: TEST_USERS[0],
        lastVisited: new Date("2021-01-14T14:30:00"),
        profile: 'Logistiker',
        credits: 45,
        language: 'Deutsch',
        duration: '6h',
        views: '10.2k',
        competences: ['ABU'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/',
            score: 0
        }, {
            id: 2,
            number: "02",
            name: "Quiz",
            duration: "1h",
            locked: false,
            lessonLink: 'https://learningapps.org/watch?v=pmfrtx6xa16',
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/',
        }, {
            id: 4,
            number: "04",
            name: "SharePoint",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://bzdch.sharepoint.com/sites/ABUUnterrichtsmaterial/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2FABUUnterrichtsmaterial%2FFreigegebene%20Dokumente%2F01%20Erstes%20Semester%2FGeld%20und%20Konsum%2FBudget%2FLösungen%20Budget%20Party%2Epdf&parent=%2Fsites%2FABUUnterrichtsmaterial%2FFreigegebene%20Dokumente%2F01%20Erstes%20Semester%2FGeld%20und%20Konsum%2FBudget&p=true&originalPath=aHR0cHM6Ly9iemRjaC5zaGFyZXBvaW50LmNvbS86Yjovcy9BQlVVbnRlcnJpY2h0c21hdGVyaWFsL0VYSF9MRFZNVzdCQW5KYTluUU5DVlprQnlyLTFzWDBzNXVRNndhTGIxRkt4YXc_cnRpbWU9dFp6YmczVEEyRWc'
        }, {
            id: 5,
            number: "04",
            name: "Lösungen",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
    {
        id: 2,
        name: "Bargeldlos bezahlen",
        level: Level.Advanced,
        skill: TEST_SKILLS.geldUndKonsum,
        category: StoreCategory.Technology,
        img: BARGELDLOS_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        },
            {
                id: 2,
                number: "02",
                name: "All about Engines",
                duration: "1h",
                locked: false,
                lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
            }, {
                id: 3,
                number: "03",
                name: "History",
                duration: "2h 30m",
                locked: false,
                lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
            }, {
                id: 4,
                number: "04",
                name: "Museum of Natural History",
                duration: "30m",
                locked: true,
                lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
            }]
    },
    {
        id: 3,
        name: "Budget Basic",
        level: Level.Basic,
        skill: TEST_SKILLS.geldUndKonsum,
        category: StoreCategory.Language,
        img: BUDGET_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'Deutsch',
        duration: '16h - 20h',
        views: '10.2k',
        competences: [
            'Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen.',
            'Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen.',
            'Sie können den Sinn und Zweck eines Budgets erklären.',
            'Sie können den Aufbau eines Budgets erklären (Einnahmen und Ausgaben, freier Betrag).',
            'Sie können den Unterschied von fixen und variablen Kosten erklären.',
            'Sie können Ihre eigenen finanziellen Möglichkeiten realistisch einschätzen.',
            'Sie können die Aufgaben einer Budgetberatungsstelle beschreiben. ',
            'Sie können Ihre Erkenntnisse zu einem Thema schriftlich festhalten, begründen und wenn nötig mit Beispielen ergänzen.',
        ],
        description: 'Endlich - der eigene Lohn! Pablo möchte sich ein neues Handy kaufen und im Sommer mit seinem Kollegen Snap zwei Wochen in die Ferien fahren ohne sich zu verschulden. Pablo träumt ausserdem von einem Sprachaufenthalt im Ausland nach der Lehre. Budgetieren ist angesagt!',
        lessons: [{
            id: 1,
            number: "01",
            name: "Kompetenznachweis",
            duration: "15m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/mission/'
        }, {
            id: 2,
            number: "02",
            name: "Lernstand 1",
            duration: "1h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/lernstand-1/'
        }, {
            id: 3,
            number: "03",
            name: "Nationalbank",
            duration: "15m",
            locked: false,
            lessonLink: 'https://www.youtube.com/embed/LkenNgjV8wA'
        }, {
            id: 4,
            number: "04",
            name: "Variable und Fixkosten erlernen",
            duration: "30m",
            locked: false,
            lessonLink: 'https://learningapps.org/watch?v=pmfrtx6xa16'
        }, {
            id: 5,
            number: "05",
            name: "Wissen 1",
            duration: "30m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/wissen-1/'
        },   {
            id: 6,
            number: "06",
            name: "Wissen 2",
            duration: "30m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/wissen-2/'
        },{
            id: 7,
            number: "07",
            name: "Austausch",
            duration: "30m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/austausch/'
        }, {
            id: 8,
            number: "08",
            name: "Lernstand 2",
            duration: "1:30h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/lernstand-2'
        }, {
            id: 9,
            number: "09",
            name: "Üben 1",
            duration: "1h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/ueben-1'
        }, {
            id: 10,
            number: "10",
            name: "Üben 2",
            duration: "1:40h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/ueben-2'
        },{
            id: 11,
            number: "11",
            name: "Üben 3",
            duration: "1:10h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/ueben-3'
        }]
    },{
        id: 4,
        name: "Budget Advanced",
        level: Level.Advanced,
        skill: TEST_SKILLS.geldUndKonsum,
        category: StoreCategory.Language,
        img: BUDGET_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'Deutsch',
        duration: '16h - 20h',
        views: '10.2k',
        competences: [
            'Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen.',
            'Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen.',
            'Sie können den Sinn und Zweck eines Budgets erklären.',
            'Sie können den Aufbau eines Budgets erklären (Einnahmen und Ausgaben, freier Betrag).',
            'Sie können die Aufgaben einer Budgetberatungsstelle beschreiben. ',
            'Sie können Ihre Erkenntnisse zu einem Thema schriftlich festhalten, begründen und wenn nötig mit Beispielen ergänzen.',
        ],
        description: 'Endlich - der eigene Lohn! Pablo möchte sich ein neues Handy kaufen und im Sommer mit seinem Kollegen Snap zwei Wochen in die Ferien fahren ohne sich zu verschulden. Pablo träumt ausserdem von einem Sprachaufenthalt im Ausland nach der Lehre. Budgetieren ist angesagt!',
        lessons: [{
            id: 1,
            number: "01",
            name: "Vertiefen 1",
            duration: "2h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/vertiefen-1/'
        },{
            id: 2,
            number: "02",
            name: "Vertiefen 2",
            duration: "2h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/vertiefen-2/'
        },{
            id: 3,
            number: "03",
            name: "Vertiefen 3",
            duration: "2h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/vertiefen-3/'
        },{
            id: 4,
            number: "04",
            name: "Reflexion",
            duration: "30m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/reflexion/'
        },]
    },{
        id: 5,
        name: "Budget Professional",
        level: Level.Professional,
        skill: TEST_SKILLS.geldUndKonsum,
        category: StoreCategory.Language,
        img: BUDGET_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'Deutsch',
        duration: '16h - 20h',
        views: '10.2k',
        competences: [
            'Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen.',
            'Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen.',
            'Sie können den Sinn und Zweck eines Budgets erklären.',
            'Sie können den Aufbau eines Budgets erklären (Einnahmen und Ausgaben, freier Betrag).',
            'Sie können die Aufgaben einer Budgetberatungsstelle beschreiben. ',
            'Sie können Ihre Erkenntnisse zu einem Thema schriftlich festhalten, begründen und wenn nötig mit Beispielen ergänzen.',
        ],
        description: 'Endlich - der eigene Lohn! Pablo möchte sich ein neues Handy kaufen und im Sommer mit seinem Kollegen Snap zwei Wochen in die Ferien fahren ohne sich zu verschulden. Pablo träumt ausserdem von einem Sprachaufenthalt im Ausland nach der Lehre. Budgetieren ist angesagt!',
        lessons: [{
            id: 1,
            number: "01",
            name: "Challenge / Projekt",
            duration: "2h",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/challenge-projekt/'
        },{
            id: 2,
            number: "02",
            name: "Chinas neue Seidenstrasse",
            duration: "30m",
            locked: false,
            lessonLink: 'https://poly.google.com/view/fUrKE2kg0Q4/embed?chrome=min'
        },{
            id: 3,
            number: "03",
            name: "Reflexion",
            duration: "30m",
            locked: false,
            lessonLink: 'https://web.culmenlxp.ch/reflexion/'
        },]
    },

    {
        id: 6,
        name: "Rechte und Pflichten",
        level: Level.Advanced,
        skill: TEST_SKILLS.neuerLebensabschnitt,
        category: StoreCategory.Language,
        img: RECHTE_IMG,
        author: TEST_USERS[2],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
    {
        id: 7,
        name: "Historischer Rundgang",
        level: Level.Professional,
        skill: TEST_SKILLS.history,
        category: StoreCategory.Language,
        img: HISTORY_IMG,
        author: TEST_USERS[3],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'Deutsch',
        duration: '6h',
        views: '10.2k',
        competences: ['Sie lernen die 7 Weltwunder kennen', 'Sie erkennten historische Orte'],
        description: 'In dieser Mission erleben die momumentale Bauwerke und historische Orte in einer 360 Grad Ansicht.',
        lessons: [{
            id: 1,
            number: "01",
            name: "7 Weltwunder",
            duration: "30m",
            locked: false,
            lessonLink: 'https://poly.google.com/view/8v-I0wldO1r/embed?chrome=min'
        }, {
            id: 2,
            number: "02",
            name: "Rio de Janeiro",
            duration: "1h",
            locked: false,
            lessonLink: 'https://poly.google.com/view/0lmKicgdgKi/embed?chrome=min'
        }, {
            id: 3,
            number: "03",
            name: "New York",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://poly.google.com/view/dd_kBQYYcfH/embed?chrome=min'
        }, {
            id: 4,
            number: "04",
            name: "Pearl Harbour",
            duration: "30m",
            locked: false,
            lessonLink: 'https://poly.google.com/view/aymK5Coo_gr/embed?chrome=min'
        },  {
            id: 5,
            number: "05",
            name: "Louvre",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        },{
            id: 6,
            number: "06",
            name: "ISS",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 7,
            number: "07",
            name: "Test",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        },]
    },

    {
        id: 8,
        name: "CSS Basic",
        level: Level.Basic,
        skill: TEST_SKILLS.webDevelopment,
        category: StoreCategory.SoftwareDevelopment,
        img: TEST_MISSION_IMG,
        author: TEST_USERS[4],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: false,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }, {
            id: 5,
            number: "05",
            name: "Louvre",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        },]
    },
    {
        id: 9,
        name: "Mechanics Basic",
        level: Level.Basic,
        skill: TEST_SKILLS.engine,
        category: StoreCategory.Technology,
        img: TEST_MISSION_IMG,
        author: TEST_USERS[4],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
    {
        id: 10,
        name: "Algebra 01",
        level: Level.Advanced,
        skill: TEST_SKILLS.mathematics,
        category: StoreCategory.Mathematics,
        img: ALGEBRA_IMG,
        author: TEST_USERS[4],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
    {
        id: 11,
        name: "JavaScript Advanced",
        level: Level.Advanced,
        skill: TEST_SKILLS.webDevelopment,
        category: StoreCategory.SoftwareDevelopment,
        img: TEST_MISSION_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
    {
        id: 12,
        name: "UML 2.0 Basic",
        level: Level.Basic,
        skill: TEST_SKILLS.webDevelopment,
        category: StoreCategory.SoftwareDevelopment,
        img: TEST_MISSION_IMG,
        author: TEST_USERS[1],
        lastVisited: new Date(),
        profile: 'Logistiker',
        credits: 45,
        language: 'English',
        duration: '6h',
        views: '10.2k',
        competences: ['competence 1', 'competence 2'],
        description: 'This is a test description, test test test test',
        lessons: [{
            id: 1,
            number: "01",
            name: "Introduction to Space",
            duration: "30m",
            locked: false,
            lessonLink: 'https://iss-sim.spacex.com/'
        }, {
            id: 2,
            number: "02",
            name: "All about Engines",
            duration: "1h",
            locked: false,
            lessonLink: 'https://old.opencascade.com/content/web-3d-demo'
        }, {
            id: 3,
            number: "03",
            name: "History",
            duration: "2h 30m",
            locked: false,
            lessonLink: 'https://petitegalerie.louvre.fr/visite-virtuelle/saison5/'
        }, {
            id: 4,
            number: "04",
            name: "Museum of Natural History",
            duration: "30m",
            locked: true,
            lessonLink: 'https://naturalhistory.si.edu/visit/virtual-tour'
        }]
    },
];

export interface Banner {
    faIcon: string;
    faBrand: boolean;
    faSolid: boolean;
    title: string;
    text: string;
    mission: Mission;
    img?: string;
    textColor?: string;
}


export const TEST_BANNERS: Banner[] = [
    {
        img: TEST_MISSION_IMG,
        textColor: 'green',
        faIcon: 'leanpub',
        faBrand: true,
        faSolid: false,
        title: 'We Recommend',
        text: 'Refine your web development skills and become a professional',
        mission: TEST_MISSIONS[10]
    },
    {
        faIcon: 'hand-sparkles',
        faBrand: false,
        faSolid: true,
        title: 'New Arrival',
        text: 'Check out our newest software engineering profile mission',
        mission: TEST_MISSIONS[11]
    },
];

export interface HomeScreenTestData {
    recommendation: {
        profileName: string;
        profileDesc: string;
        views: string;
        duration: string;
        matchedSkills: number;
        skillsToGo: number
    };
    calendarEvents: Event[];
    analytics: {
        id: string;
        progress: number;
        colors: string[];
        primaryText: string;
        secondaryText: string;
    }[];
    profileOverview: ProgressMission[];
}

export const TEST_HOME_SCREEN_DATA: HomeScreenTestData = {
    recommendation: {
        profileName: 'Recyclist',
        profileDesc: 'Recyclisten und Recyclistinnen arbeiten in Recyclingbetrieben. Sie sammeln weggeworfene Materialien, sogenannte Wertstoffe, und verwerten diese weiter. Zu diesen Wertstoffen gehören zum Beispiel Sperrgut, Elektronikschrott, Kabel, Papier, Karton, Batterien, Altöl, Kunststoffe, Bauschutt, Altmetall oder Alteisen. Diese Wertstoffe werden durch den Recyclingbetrieb abgeholt oder von Unternehmen und Privatpersonen angeliefert.',
        views: '29.5k',
        duration: '6h',
        matchedSkills: 24,
        skillsToGo: 16
    },
    calendarEvents: [{id: 1, date: new Date("2021-01-10T09:15:00"), content: 'Algebra 101'}, {
        id: 2,
        date: new Date("2021-01-10T14:45:00"),
        content: 'English C1'
    }, {id: 3, date: new Date("2021-01-14T10:15:00"), content: 'Tax & Law'}, {
        id: 4,
        date: new Date("2021-01-14T13:00:00"),
        content: 'Room 350 Engine Repair'
    }, {id: 5, date: new Date("2021-01-14T15:30:00"), content: 'Webinar EV changes our future'}],
    analytics: [{
        id: 'completion',
        progress: 75,
        colors: ['blue', 'lightgreen'],
        primaryText: '75%',
        secondaryText: 'Mission Completion'
    }, {
        id: 'video',
        progress: 80,
        colors: ['purple', 'red'],
        primaryText: '12hrs',
        secondaryText: 'Video Session Remaining'
    }, {id: 'badges', progress: 66, colors: ['grey', 'orange'], primaryText: '3', secondaryText: 'Badges Remaining'}],
    profileOverview: [{name: 'ABU', progress: 46, color: 'red'}, {
        name: 'Logistik',
        progress: 39,
        color: 'green'
    }, {name: 'ÜK', progress: 36, color: 'blue'}]
}

export interface EventInt {
    title: string,
    date: string,
    location: string,
    pic: string
}

const biologiePic = require('../img/biology.png').default;
const geographiePic = require('../img/globe.png').default;

export const oldEvents: EventInt[] = [
    {
        title: 'Biology II',
        date: 'Sun, 19 Jun 10:00 UTC+02, Online',
        location: 'Eidgenössische Technische Hochschule Zürich (ETH)',
        pic: biologiePic
    },
    {
        title: 'Geographie I',
        date: 'Sun, 19 Jun 10:00 UTC+02, Classroom 105',
        location: 'Geographisches Institut Zürich (UZH)',
        pic: geographiePic
    },
]

const economyPic = require('../img/economy.png').default;
const ukPic = require('../img/ukflag.png').default;

export const newEvents: EventInt[] = [
    {
        title: 'Microeconomics I',
        date: 'Sun, 19 Jun 10:00 UTC+02, Online',
        location: 'Zürcher Hochschule für angewandte Wissenschaften (ZHAW)',
        pic: economyPic
    },
    {
        title: 'English C2',
        date: 'Sun, 19 Jun 10:00 UTC+02, Classroom 105',
        location: 'Cambridge English \n' +
            'C2 Proficiency (CPE)',
        pic: ukPic
    },
    {
        title: 'English C2 Test',
        date: 'Sun, 19 Jun 10:00 UTC+02, Online',
        location: 'Cambridge English \n' +
            'C2 Proficiency (CPE)',
        pic: ukPic
    },
]

export const lessonCriteriaData = [
    'Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen.',
    'Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen.',
    'Sie können den Sinn und Zweck eines Budgets erklären.',
    'Sie können den Aufbau eines Budgets erklären (Einnahmen und Ausgaben, freier Betrag).',
    'Sie können den Unterschied von fixen und variablen Kosten erklären.',
    'Sie können Ihre eigenen finanziellen Möglichkeiten realistisch einschätzen.',
    'Sie können die Aufgaben einer Budgetberatungsstelle beschreiben.',
    'Sie können Ihre Erkenntnisse zu einem Thema schriftlich festhalten, begründen und wenn nötig mit Beispielen ergänzen.'
];

export const lessonLearnObjects = [
    {
        title: '01. Kompetenznachweis',
        duration: '15min',
        missionUrl: '/kompetenznachweis',
        message: ''

    },
    {
        title: '02. Lernstand 1',
        duration: '1h',
        missionUrl: '/lernstand',
        message: ''
    },
    {
        title: '03. Nationalbank',
        duration: '15min',
        missionUrl: '/nationalbank',
        message: ''
    },
    /*{
        title: '04. Variable und Fixkosten erlernen',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '05. Wissen 1',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '06. Wissen 2',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '07. Austausch',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '08. Lernstand 2',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '09. Üben 1',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '10. Üben 2',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    },
    {
        title: '11. Üben 3',
        duration: '',
        missionUrl: '/mission/3/1',
        message: 'Dieser Kurs wurde von der Lehrperson noch nicht aktiviert.'
    }*/
];



// ----------------------------------------------------------------------------------
// Mockup helper functions

export const setSkillScoreFromMission = (mission: Mission) => {
    const missionToSkillScore = Math.round((mission.score || 0) / 3 * mission.level);
    if (!mission.skill.score || mission.skill.score < missionToSkillScore) {
        mission.skill.score = missionToSkillScore;
    }
}

// Initialise skill scores based on highest mission score associated with the skill
export const setInitialSkillScores = () => {
    TEST_MISSIONS
        .filter(mission => TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id)) // only look at missions the logged in user has booked
        .forEach(setSkillScoreFromMission);
}

export const setInitialMissionScores = () => {
    TEST_MISSIONS.forEach(mission => mission.score = calcMissionScore(mission.id));
}

// Call to initialise LXP Mockup test data
export const initialiseMockup = () => {
    localStorage.setItem('userId', '1');
    // used to calculate mission scores in the LXP Mockup
    setInitialMissionScores();
    // used to calculate skill scores in the LXP Mockup
    setInitialSkillScores();
}