import React from 'react';
import { Link } from 'react-router-dom';
import { Topbar } from '../../app/topbar/Topbar';
import { HeartButton } from '../heart-button/HeartButton';
import { getTimeString } from '../helpers';

import './lesson-card.scss';

interface Props {
  img: string;
  title: string;
  author: string;
  profile: string;
  timestamp: Date;
  linkTo: string;
  nav: React.Ref<typeof Topbar>  
}

export const LessonCard: React.FC<Props> = (props) => {
  const onLinkClick = () => {
    if ((props.nav as any)?.current) {
      (props.nav as any).current.setNavElements([...(props.nav as any).current.getNavElements(), {linkTo: '/home-old', pageName: 'Home'}]);
    }
  }

  return (
    <div className="lesson-card"> 
        <img src={props.img} alt="lesson"/>
        <div className="lesson-info">
        <Link to={props.linkTo} onClick={onLinkClick}>
          <div className="title">{props.title}</div>
        </Link>
          <div className="author">{props.author}</div>
          <div className="profile">{props.profile}</div>
        </div>
        <div className="lesson-action">
          <div className="button-container">
            <HeartButton size="20px"/>
          </div>
          <div className="timestamp">{getTimeString(props.timestamp)}</div>
        </div>
    </div>
  );
}