import React from 'react';

import './avatar.scss';

export interface Props {
  /**
   * Image to display as an Avatar
   */
  img: string;
  /**
   * onClick event handler
   */
  onClick?: () => void;
}

export const Avatar: React.FC<Props> = (props) => {
  return (
    <img className="avatar" src={props.img} alt="Avatar" onClick={props.onClick}/>
  );
}