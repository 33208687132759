import React from 'react';
import { FAIcon } from '../fa-icon/FAIcon';

import './competence-list-item.scss';

export const CompetenceListItem: React.FC = (props) => {
  return (
    <div className="competence">
      <FAIcon name="check" className="check-mark" solid/>
      <div>{props.children}</div>
    </div>
  );
}