import React from 'react';
import { Avatar } from '../avatar/Avatar';
import { FollowButton } from '../follow-button/FollowButton';

import './creator-plug.scss';

interface Props {
  avatar: string;
  name: string;
  courses: string;
  followers: string;
  onFollow?: (isFollowing: boolean) => void;
}

export const CreatorPlug: React.FC<Props> = (props) => {
  const [isFollowing, setIsFollowing] = React.useState<boolean>(false);
  
  const onClick = (state: boolean) => {
    if (props.onFollow) props.onFollow(state);

    setIsFollowing(state);
  }

  return (
    <div className="creator">
      <Avatar img={props.avatar}/>
      <div className="creator__info">
        <div>by {props.name}</div>
        <div className="creator__info--details">
          <span>{props.courses} Courses</span>
          <span>{props.followers} Followers</span>
        </div>
      </div>
      <FollowButton isFollowing={isFollowing} onClick={onClick}/>
    </div>
  );
}