import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';
import PopUp from "./storepopup";

import {Link, useHistory, useLocation, useParams} from 'react-router-dom';

import './storelehrgaenge.scss';
import StorePopUp from './storepopup';
import {keycloak} from "../../../api/login";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const checkPNG = require('../../../img/check-icon.png').default;
const playPNG = require('../../../img/playbutton.png').default;
const ladyOverview = require('../../../img/lady-lesson-overview.png').default;
const deniseMerz = require('../../../img/denise.png').default;
const followButton = require('../../../img/follow-button.png').default;
const PADLOCK = require('../../../img/padlock.svg').default;
const filterIcon = require('../../../img/filter-search-icon.png').default;

const englishPNG = require('../../../img/temporary-img/english.png').default;
const germanPNG = require('../../../img/temporary-img/german.png').default;
const geldPNG = require('../../../img/temporary-img/geld.png').default;
const mathePNG = require('../../../img/temporary-img/mathe.png').default;

interface Props {

}

export const StoreLehrgaenge: React.FC<Props> = (props: Props) =>  {

  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="storelehrgaenge-container">
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Store`} subText={`Welcome to the NU Store`}/>
        <div className="main-container">
          <div className="container-up">
            {/*<div className="top-bar-store">
              <div className="store-sections">
                
                <a className="store-sections__section-button store-sections__lehrgaenge">
                  <div>Lehrgänge</div> 
                </a>
                <a className="store-sections__section-button store-sections__indivweiterbildung" href="/store/indivweiterbildung">
                  <div>Individuelle Weiterbidung</div>
                </a>
                <a className="store-sections__section-button store-sections__lernmaterial" href="/store/lernmaterial">
                  <div>Lernmaterial</div>
                </a>
              </div>
              <div className="search-components">
                <div className="search-bar-store">
                  <input type="text"/>
                </div>
                <div className="search-filter">
                <div><img src={filterIcon} /></div>

                </div>
              </div>
            </div>*/}

            <div className="store-stack-container">
                <div className="store-content-stack-container">
                    <div className="store-content">
                      <h3 className="title">All courses</h3>
                      <div className="store-items">
                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">
                                  <img className="picture-resize" src={germanPNG} alt="math-icon"/>
                              </div>
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">German</div>
                              <div className="item-type">
                                <div>LEHRGANG</div>
                              </div>
                              <div className="item-description">Migros Klubschule</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp
                              courseImg = {germanPNG}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'German'}
                              publisher = {'Migros Klubschule'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Deutsch'}
                              itemCompetenceArea = {'Sprache Lernen'}
                              estimatedTime = {'150 Stunden'}
                              descriptionTitle = {'Beschreibung:'}
                              itemDescription1 = {'In unseren kommunikativen Intensiv-Deutschkursen lernen Sie systematisch und rasch die grundlegenden Strukturen und den Wortschatz der deutschen Sprache durch Konversations-, Hörverständnis- und Wortschatzübungen, Diktate, Dialoge, Grammatik und vieles mehr. Als Ergänzung zum Deutschkurs im Gruppenunterricht steht Ihnen unser multimedialer Workshop mit permanenter Lehrerbetreuung zur Perfektionierung von Ausdruck, Aussprache, Wortschatz und Grammatik kostenlos zur Verfügung. Ausserdem können Sie einen unserer stufengerechten Deutsch Konversationskurse besuchen, um eine fliessende Anwendung der deutschen Sprache zu erreichen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon" style={{background: 'linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}}>
                              <div className="icon-content">
                                  <img className="picture-resize" src={englishPNG} alt="english-icon"/>
                              </div>
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">English</div>
                              <div className="item-type">
                                <div>LEHRGANG</div>
                              </div>
                              <div className="item-description">Migros Klubschule</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              courseImg = {englishPNG}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'English'}
                              publisher = {'Migros Klubschule'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Englisch'}
                              itemCompetenceArea = {'Sprache Lernen'}
                              estimatedTime = {'120 Stunden'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Sie wollen Englisch möglichst schnell lernen. Vielleicht haben Sie eine interessante berufliche Perspektive vor Augen oder der nächste Urlaub ist sehr nah. Wir bieten Ihnen Englisch Intensivkurse  in Zürich in allen Niveaustufen Anfänger oder Fortgeschrittene (A1, A2, B1, B2, C1, C2). Die Unterrichtszeiten sind jeden Vormittag. Günstig Englisch lernen zum Beispiel 2 Wochen für nur 300 CHF.  Sie haben die Wahl zwischen zwei Kursen. Wenn Sie schnell Englisch lernen wollen, ist der normale Intensivkurs optimal. Sie haben von Montag bis Freitag jeden Tag drei Unterrichtsstunden. Etwas entspannter ist der Semi-Intensivkurs. Dieser findet von Montag bis Donnerstag statt. Die Kursdauer beträgt in diesem Fall eineinhalb Stunden. Wenn Sie berufstätig sind, ist dieser Kurs ganz besonders für Sie geeignet, da wir einen Kurs am Abend anbieten.'}/>
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon" style={{background: 'linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}}>
                              <div className="icon-content">
                                  <img className="picture-resize" src={mathePNG} alt="math-icon"/>
                              </div>
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Math</div>
                              <div className="item-type">
                                <div>LEHRGANG</div>
                              </div>
                              <div className="item-description">Benedict Schule</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp
                                courseImg = {mathePNG}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Math'}
                              publisher = {'Benedict Schule'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Mathematik'}
                              itemCompetenceArea = {'Allgemein'}
                              estimatedTime = {'1 Monat'}
                              descriptionTitle = {'Beschreibung:'}
                              itemDescription1 = {'Mathematische Grundkenntnisse sind eine wichtige Voraussetzung für alle technischen Aus- und Weiterbildungen. Auch im Berufsleben ist entscheidend zu wissen, was weshalb wie gerechnet werden muss. Für alle, deren Mathematikkenntnisse etwas eingerostet sind oder nie besonders gut waren, bietet die IBZ einen Mathematik-Vorkurs an. Wenn du unsicher bist, ob deine Mathematikkenntnisse für die geplante Weiterbildung ausreichen, dann löse unseren Selbsteinstufungstest, den du bei den Downloads findest. Wenn du innerhalb von etwa einer Stunde problemlos alle Aufgaben schaffst, dann reichen deine Rechenkünste aus und du kannst direkt in deine Aus- oder Weiterbildung einsteigen. Wenn du nur die schwarz gedruckten Aufgaben innerhalb von 45 Minuten mühelos löst, dann lohnt sich der Vorkurs als Auffrischung. Und wenn du dich mit den Aufgaben schwertust, dann melde dich unbedingt an.'}/>
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">
                                  <img className="picture-resize" src={geldPNG} alt="english-icon"/>
                              </div>
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Geld</div>
                              <div className="item-type">
                                <div>LEHRGANG</div>
                              </div>
                              <div className="item-description">ZKB</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp
                                courseImg = {geldPNG}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Geld'}
                              publisher = {'ZKB'}
                              lastUpdated = {'19/05/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Tipps zum sparen'}
                              itemCompetenceArea = {'General'}
                              estimatedTime = {'15 Stunden'}
                              descriptionTitle = {'Beschreibung:'}
                              itemDescription1 = {'Die Schweiz ist Europameisterin. Zumindest in der Disziplin Sparen. Ob für ein Spielzeug, ein Studium, eine Reise oder später das Eigenheim: Wir sparen von klein auf für unsere Träume und Ziele. Für alle, die nicht wissen, wie sie Geld auf die Seite legen können.'}
                            />
                          </div>
                        </div>
                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>


                      </div>

  
                        
                    </div>
                </div>
            </div>  
          </div> 
        </div>   
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}


interface SubCompetenceCourseOverviewNoBackgroundProps {
    name: string;
    scoreProgress: number;
  }
  
export const SubCompetenceCourseOverviewNoBackground: React.FC<SubCompetenceCourseOverviewNoBackgroundProps> = (props: SubCompetenceCourseOverviewNoBackgroundProps) =>  {
    return (
        <div className="course-competence">
        <h3 className="course-competence__title">{props.name}</h3>
        <div className="course-competence__progress">
            <ProgressBar progress={props.scoreProgress} max={100} percentage color="#E17FC2"/>
        </div>
        </div>
    );
}

interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}

interface LessonCriteriaProps {
    text: string;
  }
  
export const LessonCriteria: React.FC<LessonCriteriaProps> = (props: LessonCriteriaProps) => {
    return (
        <div className="lesson-criteria">
            <div className="lesson-criteria__check"><img className="checkImg" src={checkPNG} alt="check"/></div>
            <div className="lesson-criteria__description">{props.text}</div>
        </div>
    );
}

interface ListLessonProps {
    title: string;
    duration: string;
}
  
export const ListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <div className="lesson">
            <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__title">{props.title}</div>
                <div className="lesson__duration">{props.duration}</div>
            </div>
        </div>
    );
}

interface AuthorProps {
    name: string;
    coursesFollowers: string;
    img: string;
}
  
export const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            <div className="author__profile-picture">
                <img className={props.img} src={props.img} alt={props.img}/>
            </div>
            <div className="author__description">
                <span className="name">{props.name}</span>
                <span className="courses-and-followers">{props.coursesFollowers}</span>
            </div>
            <div className="author__follow-button">
                <img className="author-follow-button" src={followButton} alt="follow"/>
            </div>
        </div>
    );
}





interface LessonProps {
    id: number;
    name: string;
    number: string;
    duration: string;
    locked?: boolean;
    minimize: number;
    linkTo: string;
    score?: number;
    completed?: boolean;
}



const MissionLesson: React.FC<LessonProps> = (props) => {
    const [animated, setAnimated] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
  
    const location = useLocation();
  
    React.useEffect(() => {
      if (animated) {
        window.setTimeout(() => setAnimated(false), 500);
      }
    }, [animated]);
  
    React.useEffect(() => {
      if ((props.score && props.score >= 80) || props.completed) {
        setCompleted(true);
      }
    }, [props.completed, props.score]);
  
    return (
      <Link to={props.locked ? location.pathname : props.linkTo} className="lesson-link">
        <div className={`lesson ${props.minimize ? 'minimize' : ''} ${props.minimize === props.id ? 'highlight' : ''}`} title={props.name} onMouseDown={() => setAnimated(true)}>
          <div className={`${props.locked ? 'locked' : 'unlocked'} ${animated ? 'animated' : ''}`}>
            <img src={props.locked ? PADLOCK : playPNG} alt="lesson status"/>
          </div>
          <div className={completed ? 'strike' : ''}>
            {props.number}{'. ' + props.name}
          </div>
          <div>
            {Boolean(props.score && props.score !== 0) && `${props.score}%`}
          </div>
          {!props.minimize &&
            <div>
              {props.duration}
            </div>
          }
        </div>
      </Link>
    );
  }