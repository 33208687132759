import React from 'react';
import { CompetenceListItem } from '../competence-list-item/CompetenceListItem';
import { countUp } from '../helpers';
// import { ProgressBar } from '../progress-bar/ProgressBar';

import './skill-progress-bar.scss';

export interface Props {
  /**
   * Name of the skill the progress bar represents
   */
  skill: string;
  /**
   * Progress made in % (min 0, max 100)
   */
  progress: number;
  /**
   * Color the progress bar is displayed in
   */
  color: string;
  learnings?: string[];
  showLearnings?: boolean;
  prefix?: string;
  onClick?: () => void;
}

export const SkillProgressBar: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState<number>(0);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  React.useEffect(() => {
    setExpanded(Boolean(props.showLearnings));
  }, [props.showLearnings]);

  React.useEffect(() => {
    const endProgress = Math.min(Math.max(Math.floor(props.progress), 0), 100);
    countUp(setProgress, endProgress, 0, 800 / endProgress);
  }, [props.progress]);

  const onClick = () => {
    if (props.onClick) props.onClick();
    if (props.learnings) setExpanded(!expanded);    
  }

  return (
    <div className="skill-progress-bar" onClick={onClick} style={{cursor: props.onClick ? 'pointer' : 'unset'}}>
      <div className="skill-progress-bar__header">
        <label>
          <span className="circle" style={{backgroundColor: props.color}}/>   {props.prefix ? props.prefix : 'Skill'} {props.skill}
        </label>
        <label>{progress}%</label>
      </div>
      {/* <ProgressBar color={props.color} progress={progress < 1 ? 0 : props.progress}/> */}
      {props.learnings && 
        <div className={`learnings`} style={expanded ? {maxHeight: props.learnings.length * 100, marginTop: 10} : {}}>
          {props.learnings.map(learning => (
            <CompetenceListItem>{learning}</CompetenceListItem>
          ))}
        </div>
      }
    </div>
  );
}