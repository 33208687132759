import React from 'react';
import moment from 'moment';
import { Message, OwnMessage, Timestamp } from './ChatMessages';
import { TEST_MESSAGES, TEST_USERS, User } from '../test-data';

import './chat.scss';
import { FAIcon } from '../../components/fa-icon/FAIcon';

interface Props {

}

export const Chat: React.FC<Props> = (props) => {
  const [activeChat, setActiveChat] = React.useState<number | null>(null);
  const [message, setMessage] = React.useState<string>('');

  // tmp hack to force refresh component so the artificaly sent rerply will show up on screen
  const [forceUpdate, setForceUpdate] = React.useState<boolean>(false);

  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }

  const sendMessage = () => {
    const sender = TEST_USERS.find(user => user.id === Number(localStorage.getItem('userId')));
    const receiver = TEST_USERS.find(user => user.id === activeChat);

    if (sender && receiver) {
      TEST_MESSAGES.push({to: receiver, from: sender, content: message, timestamp: new Date(), seen: true});
      window.setTimeout(() => {
        TEST_MESSAGES.push({to: sender, from: receiver, content: "Hi, what's up?", timestamp: new Date(), seen: true});
        setForceUpdate(!forceUpdate);
      }, 4000);
    }
    
    setMessage('');
  }
  
  const userId = Number(localStorage.getItem('userId'));
  const chatPreviewUsers = Array.from(new Set(TEST_MESSAGES
                            .filter(message => message.from.id === userId || message.to.id === userId)
                            .map(message => message.from.id === userId ? message.to : message.from)));
                          
  const messages = TEST_MESSAGES.filter(message => (message.from.id === userId || message.from.id === activeChat) && (message.to.id === userId || message.to.id === activeChat));
  const user = TEST_USERS.find(user => user.id === activeChat);

  return (
    <section className="chat">
      <div className="left">
        <h1>Chat</h1>
        {chatPreviewUsers.map(user => (
          <ChatPreview activeId={activeChat} user={user} onClick={setActiveChat} />
        ))}
      </div>
      <div className="right">
        {activeChat &&
          <>
            <h1>{user?.firstName} {user?.lastName}</h1>
            {messages.map((message, i) => (
              <>
                {(!messages[i - 1] || (messages[i - 1] && !moment(messages[i - 1].timestamp).isSame(message.timestamp, 'day'))) &&
                  <Timestamp timestamp={message.timestamp} />
                }
                {message.from.id === userId
                  ? <OwnMessage message={message.content} timestamp={message.timestamp}/>
                  : <Message message={message.content} timestamp={message.timestamp}/>
                }
              </>
            ))}
            <div className="input-field">
              <input type="text" value={message} onChange={onMessageChange} />
              <button onClick={sendMessage}>Send</button>
            </div>
          </>
        }
        {!activeChat &&
          <div className="empty">
            <FAIcon name="comments"/>
            <label>Select a person to chat!</label>
          </div>
        }
      </div>
    </section>
  );
}

interface ChatPreviewProps {
  active?: boolean;
  user: User;
  activeId: number | null;
  onClick: (value: number) => void;
}

const AVATAR = require('../../img/test_avatar.png').default;

const ChatPreview: React.FC<ChatPreviewProps> = (props) => {
  const onClick = () => {
    props.onClick(props.user.id);

    TEST_MESSAGES
      .filter(message => (message.from.id === userId || message.from.id === props.user.id) && (message.to.id === userId || message.to.id === props.user.id))
      .forEach(message => message.seen = true);
  }

  const userId = Number(localStorage.getItem('userId'));
  const messages = TEST_MESSAGES.filter(message => (message.from.id === userId || message.from.id === props.user.id) && (message.to.id === userId || message.to.id === props.user.id));
  const latestMessage = messages.reduce((curr, acc) => curr.timestamp > acc.timestamp ? curr : acc);
  const notificationCount = messages.filter(message => !message.seen).length;

  return (
    <div onClick={onClick} className={`chat-preview ${props.activeId === props.user.id ? 'active' : ''}`}>
      <img className="chat-preview__img" src={AVATAR} alt="avatar"/>
      <div className="chat-preview__details">
        <div className="name">{props.user.firstName} {props.user.lastName}</div>
        <div className="message" title={latestMessage.content}>{latestMessage.content}</div>
      </div>
      {notificationCount > 0 && <div className="chat-preview__notifications">{notificationCount}</div>}
    </div>
  );
}