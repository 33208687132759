import React from 'react';

import './stacked-progress-circle.scss';

export interface Props {
  /**
   * Different missions and you want to display. The progress circles max progress is reached when all missions individual progress reaches 100%
   */
  missions: ProgressMission[];
  /**
   * Radius of the circle in px
   */
  radius: number;
  /**
   * Circle troke width in px
   */
  stroke: number;
  /**
   * Set the track circles color. Defaults to #DDD
   */
  trackColor?: string;
}

export interface ProgressMission {
  name: string;
  progress: number;
  color: string;
}

export const StackedProgressCircle: React.FC<Props> = (props) => {
  const [strokeLengths, setStrokeLengths] = React.useState<number[]>([]);

  React.useEffect(() => {
    // if the stroke lenghts have never been set before, set them first to 0.
    // This way the transition animation for each stroke is triggered.
    if (!strokeLengths.length) {
      setStrokeLengths(props.missions.map((_) => 0));
    }

    calculateStrokeLengths();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.missions]);

  const calculateStrokeLengths = () => {
    const lengths = props.missions.map((mission) => (
      circumference / props.missions.length / 100 * mission.progress
    ));

    window.setTimeout(() => setStrokeLengths(lengths), 0);
  }

  const normalizedRadius = props.radius - (props.stroke * 0.5);
  const circumference = normalizedRadius * 2 * Math.PI;
  
  return (
    <svg
      className="stacked-progress-circle__svg"
      height={props.radius * 2}
      width={props.radius * 2}
    >
      <circle
        stroke={props.trackColor || "#DDD"}
        fill="transparent"
        strokeWidth={ props.stroke }
        r={ normalizedRadius }
        cx={ props.radius }
        cy={ props.radius }
      />
      {props.missions.reverse().map((mission, i) => {
        const length = strokeLengths.reduce((acc, curr, j) => j <= props.missions.length - (i + 1) ? acc + curr : acc, 0);
        
        return (
          <circle
            key={i}
            stroke={mission.color || 'red'}
            strokeLinecap="round"
            fill="transparent"
            strokeWidth={ props.stroke }
            strokeDasharray={`${length} ${circumference}`}
            r={ normalizedRadius }
            cx={ props.radius }
            cy={ props.radius }
          />
        );
      })}
    </svg>
  );
}