import React from 'react';
import { keycloak } from '../../../api/login';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';

import './berufskunde.scss';
import {useHistory} from "react-router-dom";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;

interface Props {

}

const nameCompetence1 = "Entgegennehmen von Gütern"; nameCompetence1.replace(/\n/g, "<br />");
const nameCompetence2 = "Bewirtschaften von Gütern"; nameCompetence2.replace(/\n/g, "<br />");
const nameCompetence3 = "Verteilen von Gütern"; nameCompetence3.replace(/\n/g, "<br />");
const nameCompetence4 = "Einhalten der Vorgaben zur Arbeitssicherheit"; nameCompetence4.replace(/\n/g, "<br />");
const nameCompetence5 = "Optimieren von Qualität und Ressourceneffizienz"; nameCompetence5.replace(/\n/g, "<br />");

export const BerufsKundePage: React.FC<Props> = (props: Props) =>  {
  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="allgemeinbildung-container">
      <Sidebar />
      <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
        <TopBar mainText={`Allgemeine Handlungskompetenzen`} subText={`Overview von die allgemeine Handlungskompetenzen`}/>
        <div className="subcompetences-container" style={{display: 'flex-box', marginTop: '20px', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence1} maxProgress={13} missionProgress={0} subcompetenceUrl={"/allghandlungskompetenzen"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence2} maxProgress={8} missionProgress={0} subcompetenceUrl={"/bewirtschaften"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence3} maxProgress={16} missionProgress={0} subcompetenceUrl={"/new"}/>
          </div>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence4} maxProgress={12} missionProgress={0} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence5} maxProgress={7} missionProgress={0} subcompetenceUrl={"/new"}/>
          </div>
        </div>

      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}

interface CompeteceProps {
  name: string;
  missionProgress: number;
  maxProgress: number;
  subcompetenceUrl: string;
}

export const AllgemeinbildungSubcompetences: React.FC<CompeteceProps> = (props: CompeteceProps) =>  {
  return (
    <a className="subcompetence" href={props.subcompetenceUrl}>
      <h3 className="subcompetence__title">{props.name}</h3>
      <div className="subcompetence__progress">
        <ProgressBar progress={props.missionProgress} max={props.maxProgress} text="missions" color="#FFF"/>
      </div>
    </a>
  )
}
