import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import "./eventsPage.scss";
import {oldEvents} from "../../test-data";
import {newEvents} from "../../test-data";
import AllEvents from "./Events";
import {keycloak} from "../../../api/login";
import {useHistory} from "react-router-dom";
import  Sidebar from '../../../components/sidebar/Sidebar';
import {LatestNotes} from "../../home/Home";
import {Calendar} from "../../../components/calendar/Calendar";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;

// import Calendar from 'react-calendar'

const EventsPage: React.FC = () => {



    function getOldEvents() {
        return oldEvents.map((event, index) => {
            return (
                <div key={index}>
                    <AllEvents
                        title={event.title}
                        date={event.date}
                        location={event.location}
                        pic={event.pic}/>
                </div>
            )
        })
    }

    function getNewEvents() {
        return newEvents.map((event, index) => {
            return (
                <div key={index}>
                    <AllEvents
                        title={event.title}
                        date={event.date}
                        location={event.location}
                        pic={event.pic}/>
                </div>
            )
        })
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="events-container">
            <Sidebar />
            <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
                <TopBar mainText={`Alle Events`} subText={``}/>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '25px 50px 0 50px'}}>

                    <div className="calendar-row">
                        <Calendar/>
                    </div>
                    <div className="oldevents-row">
                        <div className="events-container" style={{paddingTop:"38px", paddingRight: "48px", paddingBottom: "38px", paddingLeft: "48px"}}>
                            <div className="events-container__title">Past courses</div>
                            {getOldEvents()}
                        </div>

                    </div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '25px 50px 0 50px'}}>
                    <div className="newevents-row">
                        <div className="events-container" style={{paddingTop:"38px", paddingRight: "48px", paddingBottom: "38px", paddingLeft: "48px"}}>
                            <div className="events-container__title">Past courses</div>
                            {getNewEvents()}
                        </div>
                    </div>
                    <div className="oldevents-row" style={{marginLeft: '34px', color: '#F5F8FB', width: '100%'}}>asdf</div>
                </div>
            </div>
        </div>
    )

}

interface TopBarProps {
    mainText: string;
    subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}

interface EventProps {
    title: string,
    date: string,
    location: string,
    pic: string
}

export const OldEvents: React.FC<EventProps> = (props: EventProps) =>  {
    return (
        <div>
            <h4 className="event__title">{props.title}</h4>
            <p className="event__date">{props.date}</p>
            <p className="event__location">{props.location}</p>
        </div>
    )
}


export default EventsPage;