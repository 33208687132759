import { keycloak } from "./login";

export const request = async (url: RequestInfo, method: string = 'GET', body?: object) => {
  // refresh token if 30 seconds have passed since it was obtained
  await keycloak.instance.updateToken(30);

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${keycloak.instance.token}`);
  headers.append('Realm', `${keycloak.instance.realm}`);

  const result = await fetch(`https://f642b822-53aa-4157-8f67-5b775a96c041.mock.pstmn.io/${url}`, { method, headers, body: JSON.stringify(body) });

  // TODO: implement basic error handling for failed http requests
  if (result.status !== 200) {
    console.error('REQUEST FAILED');
    console.error(result);
  }

  return result.json();
}