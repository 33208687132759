import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useState } from 'react';

import './storepopup.scss'


interface SidebarIconProps {
    lehrerInitials: string;
    buttonBefore: string;
    buttonAfter: string;

    itemTitle: string;
    publisher: string;
    lastUpdated: string;
    lessonsNmr: string;

    itemMission: string;
    itemCompetenceArea: string;
    estimatedTime: string;
    descriptionTitle: string;
    itemDescription1: string;
    itemDescription2: string;
    itemDescription3: string;
    itemDescription4: string;
    itemDescription5: string;

}

export default function StorePopUp(props: SidebarIconProps) {

    
    const [open, setOpen] = React.useState(false);

    const lehrerInitials = props.lehrerInitials
    const buttonTextBefore = props.buttonBefore
    const buttonAfter = props.buttonAfter
    const itemTitle = props.itemTitle
    const publisher = props.publisher
    const lastUpdated = props.lastUpdated
    const lessonsNmr = props.lessonsNmr

    const itemMission = props.itemMission
    const itemCompetenceArea = props.itemCompetenceArea
    const estimatedTime = props.estimatedTime
    const descriptionTitle = props.descriptionTitle
    const itemDescription1 = props.itemDescription1
    const itemDescription2 = props.itemDescription2
    const itemDescription3 = props.itemDescription3
    const itemDescription4 = props.itemDescription4
    const itemDescription5 = props.itemDescription5

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [buttonBefore, setButtonText] = useState(buttonTextBefore); //same as creating your state variable where "Next" is the default value for buttonText and setButtonText is the setter function for your state variable instead of setState
    const [buttonModalBefore, setModalButtonText] = useState(buttonTextBefore);

    return (
        <div className="store-modal">
            {/*<Button variant="contained" className="open-modal-button" onClick={() => { handleOpen(); setButtonText(buttonAfter);}}>*/}
            <Button variant="contained" className="open-modal-button" onClick={() => { handleOpen(); setButtonText(buttonBefore);}}>
                {buttonBefore}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="paper">
                        <div className="modal-container">
                            <div className="store-item-resumee">
                                <div style={{display: 'inline-block'}}>
                                    <div className="store-item__container">
                                        <div className="store-item-icon">
                                            <div className="store-icon-content">{lehrerInitials}</div> 
                                        </div>
                                        <div className="item-brief-description">
                                            <div className="store-item__title">
                                                {itemTitle}
                                            </div>
                                            <div className="store-item__publisher">
                                                Publisher: <div>{publisher}</div> 
                                            </div>
                                            <div className="store-item__last-updated">
                                                Last updated: <div>{lastUpdated}</div>
                                            </div>
                                            {/*<div className="store-item__lessons-nmr">
                                                Lessons: <div>{lessonsNmr}</div>
                                            </div>*/}
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                {/*<div className="modal-button-container">
                                    <button className="open-modal-button" onClick={() => {setModalButtonText(buttonAfter);}}>{buttonModalBefore}</button>
                                </div>*/}
                                <div className="modal-button-container">
                                    <button className="open-modal-button" onClick={() => alert('Für Ihre Schule wurde keine Zahlungsmethode eingerichtet.')}>{buttonModalBefore}</button>
                                </div>
                            </div>
                            <div className="item-large-description">
                                <div style={{fontFamily: 'Mont-SemiBold', marginBottom: '7px'}}>{itemMission}</div>
                                <div style={{fontFamily: 'Mont-SemiBold', marginBottom: '7px'}}>Art des Lernobjekts - <div style={{display: 'inline-block'}}>{itemCompetenceArea}</div></div>
                                <div style={{fontFamily: 'Mont-SemiBold', marginBottom: '20px'}}>Richtzeit - <div style={{display: 'inline-block'}}>{estimatedTime}</div></div>
                                <div style={{fontFamily: 'Mont-SemiBold', marginBottom: '2px'}}>{descriptionTitle}</div>
                                <div style={{marginBottom: '3px'}}>{itemDescription1}</div>
                                <div style={{marginBottom: '3px'}}>{itemDescription2}</div>
                                <div style={{marginBottom: '3px'}}>{itemDescription3}</div>
                                <div style={{marginBottom: '3px'}}>{itemDescription4}</div>
                                <div style={{marginBottom: '3px'}}>{itemDescription5}</div>
                            </div>
                        </div>
                    
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}