import './latestNotes.scss';
import {Note} from "./LatestNotesItem";
import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import NotesSearch from "./NotesSearch";
import Sidebar from "../../../components/sidebar/Sidebar";
import {keycloak} from "../../../api/login";
import {useHistory} from "react-router-dom";
import {Calendar} from "../../../components/calendar/Calendar";

const editSVG = require('../../../img/edit.svg').default;
const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;

export const LatestNotes: React.FC = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="latestnotes-container">
            <Sidebar />
            <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
                <TopBar mainText={`Alle Events`} subText={``}/>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '25px 50px 0 50px'}}>
                    <div className="latest-notes">
                        <div>
                            <h3 className="latest-notes__title">Latest Notes</h3>
                            <img src={editSVG} alt="edit"/>
                        </div>
                        <Note date={new Date('2021-04-06T12:31:00')} text="Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled i"/>
                        <Note date={new Date('2021-04-11T14:52:00')} text="Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled i"/>
                    </div>
                </div>
            </div>
        </div>
    )

}

interface TopBarProps {
    mainText: string;
    subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}

interface EventProps {
    title: string,
    date: string,
    location: string,
    pic: string
}

export const OldEvents: React.FC<EventProps> = (props: EventProps) =>  {
    return (
        <div>
            <h4 className="event__title">{props.title}</h4>
            <p className="event__date">{props.date}</p>
            <p className="event__location">{props.location}</p>
        </div>
    )
}

export default LatestNotes;