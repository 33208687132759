import React from 'react';

import './follow-button.scss';

export interface Props {
  /** 
   * Wether to display in the following or not following state
   */
  isFollowing: boolean;
  /**
   * onClick handler
   */
  onClick: (statet: boolean, event: React.MouseEvent) => void;
}

export const FollowButton: React.FC<Props> = (props) => {
  const onClick = (event: React.MouseEvent) => {
    props.onClick(!props.isFollowing, event);
  }
  
  return (
    <button className={`follow-button ${props.isFollowing ? 'following' : ''}`} onClick={onClick}>
      {props.isFollowing ? 'Following' : '+ Follow'}
    </button>
  );
}