import React from 'react';
import { keycloak } from '../../../api/login';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';

import './ueberbetrieblichekurse.scss';
import {useHistory} from "react-router-dom";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;

interface Props {

}

const nameCompetence1 = "Geld & \nKonsum"; nameCompetence1.replace(/\n/g, "<br />");
const nameCompetence2 = "Risiko & \nSicherheit"; nameCompetence2.replace(/\n/g, "<br />");
const nameCompetence3 = "Wohnen & \nZusammenleben"; nameCompetence3.replace(/\n/g, "<br />");
const nameCompetence4 = "Mitbestimmung \nim Staat"; nameCompetence4.replace(/\n/g, "<br />");
const nameCompetence5 = "Arbeitswelt & \nZukunft"; nameCompetence5.replace(/\n/g, "<br />");
const nameCompetence6 = "Gesundheit"; nameCompetence6.replace(/\n/g, "<br />");
const nameCompetence7 = "Ökologische \nHerausforderungen"; nameCompetence7.replace(/\n/g, "<br />");
const nameCompetence8 = "Kunst & \nKultur"; nameCompetence8.replace(/\n/g, "<br />");
const nameCompetence9 = "Sprache & \nKomunikation"; nameCompetence9.replace(/\n/g, "<br />");
const nameCompetence10 = "Start in die \nBerufswelt"; nameCompetence10.replace(/\n/g, "<br />");
const nameCompetence11 = "Steuern & \nRecht"; nameCompetence11.replace(/\n/g, "<br />");
const nameCompetence12 = "Wirtschaft"; nameCompetence12.replace(/\n/g, "<br />");

export const UeberbetrieblicheKursePage: React.FC<Props> = (props: Props) =>  {
  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="allgemeinbildung-container">
      <Sidebar />
      <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
        <TopBar mainText={`Überbetriebliche Kurse`} subText={``}/>
        <div className="subcompetences-container" style={{display: 'flex-box', marginTop: '20px', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence1} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence2} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence3} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
          </div>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence4} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence5} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence6} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
          </div>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence7} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence8} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence9} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
          </div>
          <div className="subcompetences-row">
            <AllgemeinbildungSubcompetences name={nameCompetence10} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence11} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
            <AllgemeinbildungSubcompetences name={nameCompetence12} maxProgress={25} missionProgress={12} subcompetenceUrl={"/new"}/>
          </div>
        </div>

      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

  const history = useHistory();
  const navigateToProfile = () => history.push('/myprofile');
  const navigateToStore = () => history.push('/store/lehrgaenge')

  return (
      <div className="top-bar">
        <div className="top-bar__text">
          <span className="top-bar__text--main">{props.mainText}</span>
          <span className="top-bar__text--sub">{props.subText}</span>
        </div>
        <div className="top-bar__nav">
          <div className="top-bar__nav--elem" onClick={navigateToStore} ><img src={bagSVG} alt="bag"/></div>
          <div className="top-bar__nav--elem" onClick={navigateToProfile}><img src={profileSVG} alt="profile"/></div>
          <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
        </div>
      </div>
  );
}

interface CompeteceProps {
  name: string;
  missionProgress: number;
  maxProgress: number;
  subcompetenceUrl: string;
}

export const AllgemeinbildungSubcompetences: React.FC<CompeteceProps> = (props: CompeteceProps) =>  {
  return (
    <a className="subcompetence" href={props.subcompetenceUrl}>
      <h3 className="subcompetence__title">{props.name}</h3>
      <div className="subcompetence__progress">
        <ProgressBar progress={props.missionProgress} max={props.maxProgress} text="missions" color="#FFF"/>
      </div>
    </a>
  )
}
