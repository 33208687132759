import React from 'react';
import { Avatar } from '../avatar/Avatar';
import { getTimeString } from '../helpers';

import './comment.scss';

export interface Props {
  /**
   * Name of the user
   */
  name: string;
  /**
   * Text to display
   */
  comment: string;
  /**
   * Date the comment was posted
   */
  date: Date;
  /**
   * Image to display as an Avatar
   */
  img: string;
}

export const Comment: React.FC<Props> = (props) => {
  return (
    <div className="comment">
      <Avatar img={props.img} />
      <div className="content">
        <span className="name">{props.name}</span>
        {props.comment}
        <div className="date">{getTimeString(props.date)}</div>
      </div>
    </div>
  );
}