import React from 'react';
import { CompetenceListItem } from '../../components/competence-list-item/CompetenceListItem';
import { CreatorPlug } from '../../components/creator-plug/CreatorPlug';
import { FAIcon } from '../../components/fa-icon/FAIcon';
import { BannerCard, MissionCard } from '../../components/mission-card/MissionCard';
import { SkillProgressBar } from '../../components/skill-progress-bar/SkillProgressBar';
import { Mission, TEST_BANNERS, TEST_MISSIONS, TEST_USERS } from '../test-data';

import './explore.scss';

const MISSION_IMG = require('../../img/html.jpg').default;
const AVATAR = require('../../img/test_avatar.png').default;

export enum StoreCategory {
  All = 0,
  Technology = 1,
  Mathematics = 2,
  Language = 3,
  SoftwareDevelopment = 4,
}

interface Props {
  missions: Mission[];
}

export const Explore: React.FC<Props> = (props) => {
  const [selectedCategory, setSelectedCategory] = React.useState<StoreCategory>(StoreCategory.All);
  const [selectedMission, setSelectedMission] = React.useState<number | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  // tmp hack to force refresh component
  const [forceUpdate, setForceUpdate] = React.useState<boolean>(false);

  const onSeachValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  const cancelSearch = () => {
    setSearchValue('');
  }

  const onBooking = () => {
    if (selectedMission && !TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(selectedMission)) {
      TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.push(selectedMission);
      setForceUpdate(!forceUpdate);
      setButtonClicked(true);
      window.setTimeout(() => setButtonClicked(false), 3000);
    }
  }

  const mission = TEST_MISSIONS.find(mission => mission.id === selectedMission);

  return (
    <section className="explore">
      <div className="left">
        <h1>Explore Missions</h1>
        <div className="explore-content">
          <div className="search-field">
            <input type="text" value={searchValue} onChange={onSeachValueChange} />
            <button onClick={cancelSearch} >Cancel</button>
          </div>
          <ul className="categories">
            <CategoryTag id={StoreCategory.All} activeId={selectedCategory} setSelectedCategory={setSelectedCategory} name="All"/>
            <CategoryTag id={StoreCategory.Technology} activeId={selectedCategory} setSelectedCategory={setSelectedCategory} name="Technology"/>
            <CategoryTag id={StoreCategory.Mathematics} activeId={selectedCategory} setSelectedCategory={setSelectedCategory} name="Mathematics"/>
            <CategoryTag id={StoreCategory.Language} activeId={selectedCategory} setSelectedCategory={setSelectedCategory} name="Language"/>
            <CategoryTag id={StoreCategory.SoftwareDevelopment} activeId={selectedCategory} setSelectedCategory={setSelectedCategory} name="Software Development"/>
          </ul>
          <div className="banners">
            {TEST_BANNERS.map(banner => (
              <BannerCard id={banner.mission.id} img={banner.img} textColor={banner.textColor} booked={TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(banner.mission.id)} setSelected={setSelectedMission} faName={banner.faIcon} faBrand={banner.faBrand} faSolid={banner.faSolid} bannerTitle={banner.title} bannerText={banner.text} missionName={banner.mission.name} skillName={banner.mission.skill.name} missionImg={banner.mission.img}/>
            ))}
          </div>
          <div className="missions">
            {props.missions
              .filter(mission => mission.category === selectedCategory || selectedCategory === StoreCategory.All)
              .filter(mission => !searchValue || mission.name.toLowerCase().includes(searchValue.toLowerCase()) || mission.skill.name.toLowerCase().includes(searchValue.toLowerCase()))
              .map(mission => (
                <MissionCard id={mission.id} booked={TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id)} setSelected={setSelectedMission} missionName={mission.name} skillName={mission.skill.name} missionImg={mission.img}/>
            ))}
          </div>
        </div>
      </div>
      <div className={`right ${selectedMission ? 'show' : 'hide'}`}>
        {mission &&
          <>
            <h1><FAIcon name="angle-left" solid className="back-button" onClick={() => setSelectedMission(null)}/> {mission.name}</h1>
            <div className="mission-wrapper">
              <div className="mission-details">
                <div className="mission-details__wrapper">
                  <img className="mission-details__wrapper--visual" src={mission.img} alt="mission"/>
                  <div className="mission-details__wrapper--text">
                    {mission.description}
                  </div>
                </div>
                <div className="mission-details__credits">
                  <div>{mission.credits} Credits for Web Development</div>
                  <div><FAIcon name="clock" /> {mission.duration}     <FAIcon name="eye"/> {mission.views}</div>
                  <div><CreatorPlug name={`${mission.author.firstName} ${mission.author.lastName}`} followers={mission.author.followers.toString()} courses={mission.author.courses.toString()} avatar={AVATAR}/></div>
                </div>
              </div>
              <div className="mission-coverage">
                <h3>My Skill Coverage</h3>
                <SkillProgressBar progress={mission.skill.score || 0} skill={mission.skill.name} color={mission.skill.color}/>
                <h4>What Will I Learn?</h4>
                <ul className="mission-coverage__competences">
                  {mission.competences.map(competence => (
                    <CompetenceListItem>
                      {competence}
                    </CompetenceListItem>
                  ))}
                </ul>
                <div className="mission-coverage__additional-info">
                  <div>
                    <div>Language</div>
                    <div>{mission.language}</div>
                  </div>
                  <div>
                    <div>Expected time to complete</div>
                    <div>{mission.duration}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`booking-area`}>
              <div className={`on-booking ${buttonClicked ? 'expand' : ''}`}>
                <div className={buttonClicked ? 'show' : ''}><FAIcon name="check-circle"/></div> Mission Added to Profile
              </div>
              <button className={TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id) ? 'booked' : ''} onClick={onBooking}>{TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id) ? 'You already booked this Mission' : 'Book this Mission'}</button>
            </div>
          </>
        }
      </div>
    </section>
  );
}

interface TagProps {
  name: string;
  id: StoreCategory;
  activeId: number;
  setSelectedCategory: (value: number) => void;
}

const CategoryTag: React.FC<TagProps> = (props) => {
  const onClick = () => {
    props.setSelectedCategory(props.id);
  }

  return (
    <li onClick={onClick} className={props.activeId === props.id ? 'active' : ''}>{props.name}</li>
  );
}