import moment from 'moment';
import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';

import './geldundkonsum.scss';
import {keycloak} from "../../../api/login";
import {useHistory} from "react-router-dom";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;

interface Props {

}

export const BewirtschaftenPage: React.FC<Props> = (props: Props) =>  {

  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="geldundkonsum-container">
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Allgemeine Handlungskompetenzen`} subText={`It’s currently ${moment().format('HH:mm')}, Let’s make this a Productive Day`}/>
        <h3 style={{margin: '30px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Bewirtschaften von Gütern</h3>
        
        <div className="competence-container" >
          <Competence name="Score | 0% " scoreProgress={0}/>
        </div>

        {/** First Course Level Stack*/}
        <div className="level-stack-container">
          <div className="course-level-stack-container">
            <div className="nu-level">
              <div className="nu-level__left-side">
                <div>
                  <h3 className="nu-level__title">nu. basic</h3>
                </div>
                <Course titel="B1 – Güter einlagern" actualScore={0} scoreTitel="0% Score" actualLessons="0/3 Lesson" color="#E17FC2" url="/lesson/allgemeinbildung/budgetbasic"/>
              </div>
            </div>

            <div className="nu-level-progress">
              <div>
                <h3 className="nu-level__title">Progress</h3>
              </div>
              <CourseProgress  actualScore={0} text="Test 0/1" color="#00F6E7"/>
            </div>
          </div>
        </div>

        {/** Second Course Level Stack*/}
        <div className="level-stack-container">
          <div className="course-level-stack-container">
            <div className="nu-level">
              <div className="nu-level__left-side">
                <div>
                  <h3 className="nu-level__title">nu. profesional</h3>
                </div>
                <Course titel="B2 – Güterbestand sichern" actualScore={0} scoreTitel="0% Score" actualLessons="0/2 Lesson" color="#5D4ECC" url="/lesson/allgemeinbildung/budgetbasic"/>
              </div>
            </div>

            <div className="nu-level-progress">
              <div>
                <h3 className="nu-level__title">Progress</h3>
              </div>
              <CourseProgress  actualScore={0} text="Test 0/1" color="#00F6E7"/>
            </div>
          </div>
        </div>

        {/** Third Course Level Stack*/}
        <div className="level-stack-container">
          <div className="course-level-stack-container">
            <div className="nu-level">
              <div className="nu-level__left-side">
                <div>
                  <h3 className="nu-level__title">nu. master</h3>
                </div>
                <Course titel="B3 – Güter kommissionieren" actualScore={0} scoreTitel="0% Score" actualLessons="0/3 Lesson" color="#1EA0FB" url="/lesson/allgemeinbildung/budgetbasic"/>
              </div>
            </div>

            <div className="nu-level-progress">
              <div>
                <h3 className="nu-level__title">Progress</h3>
              </div>
              <CourseProgress  actualScore={0} text="Test 0/2" color="#00F6E7"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

  function logout() {
    keycloak.instance.logout();
  }

  const history = useHistory();
  const navigateToProfile = () => history.push('/myprofile');
  const navigateToStore = () => history.push('/store/lehrgaenge')

  return (
      <div className="top-bar">
        <div className="top-bar__text">
          <span className="top-bar__text--main">{props.mainText}</span>
          <span className="top-bar__text--sub">{props.subText}</span>
        </div>
        <div className="top-bar__nav">
          <div className="top-bar__nav--elem" onClick={navigateToStore} ><img src={bagSVG} alt="bag"/></div>
          <div className="top-bar__nav--elem" onClick={navigateToProfile}><img src={profileSVG} alt="profile"/></div>
          <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
        </div>
      </div>
  );
}

interface CompeteceProps {
  name: string;
  scoreProgress: number;
}

export const Competence: React.FC<CompeteceProps> = (props: CompeteceProps) =>  {
  return (
    <div className="competence">
      <h3 className="competence__title">{props.name}</h3>
      <div className="competence__progress">
        <ProgressBar progress={props.scoreProgress} max={100} percentage color="#FFF"/>
      </div>
    </div>
  )
}





interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
  url: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <a href={props.url}>
      <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>

    </a>
    
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}