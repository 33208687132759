import './sidebar.scss'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import {useState} from "react";

const logoSVG = require('../../img/logo.svg').default;
const buySVG = require('../../img/buy.svg').default;
const calendarSVG = require('../../img/calendar.svg').default;
const folderSVG = require('../../img/folder.svg').default;
const homeSVG = require('../../img/home.svg').default;
const paper = require('../../img/paper.svg').default;
const workSVG = require('../../img/work.svg').default;
const help = require('../../img/temporary-img/exclamation-16.png').default; // Temporary Help Logo
const tutorial = require('../../img/temporary-img/play-3-16.png').default; // Temporary Tutorial Logo


const homeIcon = classNames('sidebar-icon', 'home-icon')
const subcompetencesIcon = classNames('sidebar-icon', 'subcompetences-icon')
const notesIcon = classNames('sidebar-icon', 'notes-icon');
const lessonIcon = classNames('sidebar-icon', 'lesson-icon');
const storeIcon = classNames('sidebar-icon', 'store-icon');
const eventsIcon = classNames('sidebar-icon', 'events-icon');
const helpIcon = classNames('sidebar-icon', 'help-icon');
const tutorialIcon = classNames('sidebar-icon', 'tutorial-icon');


function Sidebar(){

    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

    const handleToggler = () => {
        if (isExpanded) {
            setIsExpanded(false);
            localStorage.setItem('sidebar-collapsed', JSON.stringify(true));
            return;
        }
        setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed')
    }

    return(
        <div className="container-sidebar">
            <div className={isExpanded ? "Sidebar" : "Sidebar collapsed"}>
                <div className="sidebar-logo">
                    <img src={logoSVG} onClick={handleToggler} alt="logo" className="sidebar-logo-icon"/>
                </div>
                <div className="sidebar__navigation sidebar-items">
                    <NavLink to="/new" className="item home-item">
                        <HomeIcon img={homeSVG} className={homeIcon}/>
                        <span className="sidebar-text">Home</span>
                    </NavLink>
                    <NavLink to="/subcompetences/all" className="item subcompetences-item">
                            <CompetencesIcon img={workSVG} className={subcompetencesIcon} />
                            <span className="sidebar-text">Competences</span>
                    </NavLink>
                    <NavLink to="/yournotes" className="item notes-item">
                        <NotesIcon img={paper} className={notesIcon} />
                        <span className="sidebar-text">Notes</span>
                    </NavLink>
                    <NavLink to="/lesson/allgemeinbildung/budgetbasic" className="item lesson-item">
                        <LessonIcon img={folderSVG} className={lessonIcon} />
                        <span className="sidebar-text">Lesson</span>
                    </NavLink>
                    <NavLink to="/store/all" className="item store-item">
                        <StoreIcon img={buySVG} className={storeIcon} />
                        <span className="sidebar-text">Store</span>
                    </NavLink>
                    <NavLink to="/events" className="item events-item">
                        <EventsIcon img={calendarSVG} className={eventsIcon} />
                        <span className="sidebar-text">Events</span>
                    </NavLink>

                    <button className="item help-item support-button" onClick={() => alert('Für Ihre Schule wurde keine Hilfskonfiguration eingerichtet.')}>
                        <HelpIcon img={help} className={helpIcon} />
                        <span className="sidebar-text">Help</span>
                    </button>

                    <button className="item tutorial-item support-button" onClick={() => alert('Für Ihre Schule wurde keine Tutorialkonfiguration eingerichtet.')}>
                        <TutorialIcon img={tutorial} className={tutorialIcon}/>
                        <span className="sidebar-text">Tutorial</span>
                    </button>

                </div>
            </div>
        </div>
    );
}

export default Sidebar;

interface SidebarIconProps {
    img: string;
    className: string;
}
  

export const HomeIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const homeIcon = classNames('sidebar-icon', 'home-icon');
    return (
        <div className={homeIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const CompetencesIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const competencesIcon = classNames('sidebar-icon', 'subcompetences-icon');
    return (
        <div className={competencesIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const NotesIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const notesIcon = classNames('sidebar-icon', 'notes-icon');
    return (
        <div className={notesIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const LessonIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const lessonIcon = classNames('sidebar-icon', 'lesson-icon');
    return (
        <div className={lessonIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const StoreIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const storeIcon = classNames('sidebar-icon', 'store-icon');
    return (
        <div className={storeIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const EventsIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const eventsIcon = classNames('sidebar-icon', 'events-icon');
    return (
        <div className={eventsIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}

export const HelpIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const helpIcon = classNames('sidebar-icon', 'help-icon');
    return (
        <div className={helpIcon}>
            <img src={props.img} alt="logo"/>
        </div>
        );
}

export const TutorialIcon: React.FC<SidebarIconProps> = (props: SidebarIconProps) => {
    const tutorialIcon = classNames('sidebar-icon', 'tutorial-icon');
    return (
        <div className={tutorialIcon}>
            <img src={props.img} alt="logo"/>
        </div>
    );
}