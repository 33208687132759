import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';

import './lernmaterial.scss';
import  StorePopUp from './lernmaterialpopup';
import {keycloak} from "../../../api/login";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const checkPNG = require('../../../img/check-icon.png').default;
const playPNG = require('../../../img/playbutton.png').default;
const ladyOverview = require('../../../img/lady-lesson-overview.png').default;
const deniseMerz = require('../../../img/denise.png').default;
const followButton = require('../../../img/follow-button.png').default;
const PADLOCK = require('../../../img/padlock.svg').default;
const filterIcon = require('../../../img/filter-search-icon.png').default;

const wordLogo = require("../../../img/word-logo.png").default;
const excelLogo = require("../../../img/excel-logo.png").default;
const powerpointLogo = require("../../../img/powerpoint-logo.png").default;
const accessLogo = require("../../../img/access-logo.png").default;
const outlookLogo = require("../../../img/outlook-logo.png").default;

interface Props {

}

export const StoreLernmaterial: React.FC<Props> = (props: Props) =>  {

  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="lernmaterial-container">
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Store`} subText={`Welcome to the NU Store`}/>
        <div className="main-container">
          <div className="container-up">
            {/*<div className="top-bar-store">
              <div className="store-sections">
              
                <a className="store-sections__section-button store-sections__lehrgaenge" href="/store/lehrgaenge">
                  <div>Lehrgänge</div> 
                </a>
                <a className="store-sections__section-button store-sections__indivweiterbildung" href="/store/indivweiterbildung">
                  <div>Individuelle Weiterbidung</div>
                </a>
                <a className="store-sections__section-button store-sections__lernmaterial" href="#">
                  <div>Lernmaterial</div>
                </a>
              </div>
              <div className="search-components">
                <div className="search-bar-store">
                  <input type="text"/>
                </div>
                <div className="search-filter">
                <div><img src={filterIcon} /></div>

                </div>
              </div>
            </div>*/}

            <div className="store-stack-container">
                <div className="store-content-stack-container">
                    <div className="store-content">
                      <h3 className="title">Lernmaterial</h3>
                      <div className="store-items">
                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">BW</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Brennpunkt Wirtschaft und Gesellschaft</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Verlag SKV</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">RK</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Rechnungswesen für Kaufleute</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Verlag SKV</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">BT</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">BEC Testbuilder Preliminary</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Orell Füssli</div>
                              <div className="item-level">Beginner</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">BE</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Business Essentials B1</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Orell Füssli</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />



                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">IE</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">International Express Pre-Intermediate</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Orell Füssli</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <div className="icon-content">IS</div> 
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">IKA SIZ AU Windows 10</div>
                              <div className="item-type">
                                <div>Lernmaterial</div>
                              </div>
                              <div className="item-description">Wings Lernmedien AG</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <StorePopUp 
                              lehrerInitials = {'LT'}
                              buttonBefore = {'Add'}
                              buttonAfter = {'Added'}
                              itemTitle = {'Logistiker/in EFZ'}
                              publisher = {'BBZ'}
                              lastUpdated = {'12/07/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Lehrgang Logistiker EFZ'}
                              itemCompetenceArea = {'Lehrgang'}
                              estimatedTime = {'4 Jahren'}
                              descriptionTitle = {'Tätigkeiten'}
                              itemDescription1 = {'Logistiker/innen EFZ kennen die gesamte Prozesskette und reagieren deshalb angemessen auf Unregelmässigkeiten. Sie garantieren eine hohe Qualität und Wirtschaftlichkeit der Arbeitsabläufe, halten die Vorgaben zum Gesundheits- und Umweltschutz und zur Arbeitssicherheit ein sowie achten auf hohe Energie- und Ressourceneffizienz. Sie arbeiten vertieft entweder im Bereich Distribution, Lager oder Verkehr.'}
                              itemDescription2 = {'In der Distribution stellen die Logistiker/innen EFZ insbesondere Sendungen den Endkunden mit dem Roller oder dem Lieferwagenzu. Die Berufsleute haben täglich Kundenkontakt und beraten Kunden zu Dienstleistungen und Produkten ihres Ausbildungsbetriebs. Sie nehmen auch Sendungen vom Absenderkunden entgegen und bereiten die Sendungen für die maschinelle oder manuelle Sortierung vor. Sie sortieren Sendungen nach vorgegebenen Kriterien und organisieren deren Zustellung, wobei sie die Sendungsart, den Empfänger, das Transportmittel, die Destination und vorgegebene Zeitfenster berücksichtigen müssen.'}
                              itemDescription3 = {'Im Bereich Lager bewirtschaften Logistiker/innen EFZ Güterlager und optimieren deren Nutzung. Sie nehmen Güter entgegen, kontrollieren sie und verbuchen die Wareneingänge im Computersystem. Mit Hilfe von Flurförderzeugen oder komplexen Fördersystemen lagern sie die Güter in geeigneter Weise ein. Trifft eine Bestellung bei ihnen ein, bereiten sie die entsprechenden Waren für die Auslieferung oder den Versand vor, verpacken und beschriften sie, erstellen die Lieferpapiere und beladen die Fahrzeuge fachgerecht und sicher. Weiter prüfen sie regelmässig die Lagerbestände.'}
                              itemDescription4 = {'Im Bereich Verkehr arbeiten Logistiker/innen EFZ vor allem auf Rangierbahnhöfen. Sie formieren Reise- und Güterzüge, indem sie diese kuppeln und entkuppeln und so für die nächste Fahrt bereitstellen. Dabei stehen sie mit den Lokführerinnen und Lokführern in Funkkontakt und halten sich strikte an die Sicherheitsbestimmungen. Ausserdem be- und entladen sie Bahn- und Lastwagen und übernehmen Aufgaben im Lager von Industriewerken.'}
                              itemDescription5 = {'In allen Bereichen nutzen Logistiker/innen EFZ Computer und Scanner als wichtige Hilfsmittel. Sie registrieren damit Güter, führen Bestandkontrollen durch, planen mit einfachen Berechnungen die Lagerbewirtschaftung, erstellen Statistiken, bearbeiten Bestellungen und bestätigen Sendungsauslieferungen.'}
                            />
                          </div>
                        </div>

                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>


                      </div>

  
                        
                    </div>
                </div>
            </div>  
          </div> 
        </div>   
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={logout} alt="notification"/></div>
            </div>
        </div>
    );
}


interface SubCompetenceCourseOverviewNoBackgroundProps {
    name: string;
    scoreProgress: number;
  }
  
export const SubCompetenceCourseOverviewNoBackground: React.FC<SubCompetenceCourseOverviewNoBackgroundProps> = (props: SubCompetenceCourseOverviewNoBackgroundProps) =>  {
    return (
        <div className="course-competence">
        <h3 className="course-competence__title">{props.name}</h3>
        <div className="course-competence__progress">
            <ProgressBar progress={props.scoreProgress} max={100} percentage color="#E17FC2"/>
        </div>
        </div>
    );
}

interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}

interface LessonCriteriaProps {
    text: string;
  }
  
export const LessonCriteria: React.FC<LessonCriteriaProps> = (props: LessonCriteriaProps) => {
    return (
        <div className="lesson-criteria">
            <div className="lesson-criteria__check"><img className="checkImg" src={checkPNG} alt="check"/></div>
            <div className="lesson-criteria__description">{props.text}</div>
        </div>
    );
}

interface ListLessonProps {
    title: string;
    duration: string;
}
  
export const ListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <div className="lesson">
            <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__title">{props.title}</div>
                <div className="lesson__duration">{props.duration}</div>
            </div>
        </div>
    );
}

interface AuthorProps {
    name: string;
    coursesFollowers: string;
    img: string;
}
  
export const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            <div className="author__profile-picture">
                <img className={props.img} src={props.img} alt={props.img}/>
            </div>
            <div className="author__description">
                <span className="name">{props.name}</span>
                <span className="courses-and-followers">{props.coursesFollowers}</span>
            </div>
            <div className="author__follow-button">
                <img className="author-follow-button" src={followButton} alt="follow"/>
            </div>
        </div>
    );
}





interface LessonProps {
    id: number;
    name: string;
    number: string;
    duration: string;
    locked?: boolean;
    minimize: number;
    linkTo: string;
    score?: number;
    completed?: boolean;
}



const MissionLesson: React.FC<LessonProps> = (props) => {
    const [animated, setAnimated] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
  
    const location = useLocation();
  
    React.useEffect(() => {
      if (animated) {
        window.setTimeout(() => setAnimated(false), 500);
      }
    }, [animated]);
  
    React.useEffect(() => {
      if ((props.score && props.score >= 80) || props.completed) {
        setCompleted(true);
      }
    }, [props.completed, props.score]);
  
    return (
      <Link to={props.locked ? location.pathname : props.linkTo} className="lesson-link">
        <div className={`lesson ${props.minimize ? 'minimize' : ''} ${props.minimize === props.id ? 'highlight' : ''}`} title={props.name} onMouseDown={() => setAnimated(true)}>
          <div className={`${props.locked ? 'locked' : 'unlocked'} ${animated ? 'animated' : ''}`}>
            <img src={props.locked ? PADLOCK : playPNG} alt="lesson status"/>
          </div>
          <div className={completed ? 'strike' : ''}>
            {props.number}{'. ' + props.name}
          </div>
          <div>
            {Boolean(props.score && props.score !== 0) && `${props.score}%`}
          </div>
          {!props.minimize &&
            <div>
              {props.duration}
            </div>
          }
        </div>
      </Link>
    );
  }