import React from 'react';
import { TEST_USERS } from '../../app/test-data';
import { FAIcon } from '../fa-icon/FAIcon';

import './mission-card.scss';

export interface Props {
  id?: number;
  setSelected?: (id: number) => void;
  /**
   * Name of the skill the mission is teaching
   */
  skillName: string;
  /**
   * Name of the mission this card is representing
   */
  missionName: string;
  /**
   * Image to display 
   */
  missionImg: string;
  /**
   * onClick callback handler
   */
  booked?: boolean;
  onClick?: () => void;
  onBooking?: () => void;
}

export const MissionCard: React.FC<Props> = (props) => {
  const [booked, setBooked] = React.useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  React.useEffect(() => {
    setBooked(props.booked || false);
  }, [props.booked]);

  const onClick = () => {
    if (props.id && props.setSelected) props.setSelected(props.id);
    if (props.onClick) props.onClick();
  }

  const onBooking = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (!booked && props.id) {
      TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.push(props.id);
      setBooked(true);
      setButtonClicked(true);
      window.setTimeout(() => setButtonClicked(false), 3000);
    }
  }

  return (
    <div className="scene">
      <div className={`card ${buttonClicked ? 'flipped' : ''}`}>
        <div className="mission-card cardface" onClick={onClick}>
          <img src={props.missionImg} alt="mission"/>
          <div className="content">
            <div className="labels">
              <label className="skill-name">Skill {props.skillName}</label>
              <label className="mission-name">{props.missionName}</label>
            </div>
            <button onClick={onBooking} className={`booking-button ${booked ? 'booked' : ''}`}><FAIcon name={`${booked ? 'check-circle' : 'plus-circle'}`} solid={!booked} /></button>
          </div>
        </div>
        <div className="backside cardface">
          Mission Added <br /> to Profile
          <div className={buttonClicked ? 'show' : ''}><FAIcon name="check-circle"/></div>
        </div>
      </div>
    </div>
  );
}

interface BannerProps extends Props {
  bannerText: string;
  faSolid?: boolean;
  faBrand?: boolean;
  bannerTitle: string;
  faName: string;
  img?: string;
  textColor?: string;
}

export const BannerCard: React.FC<BannerProps> = (props) => {
  const [booked, setBooked] = React.useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  React.useEffect(() => {
    setBooked(props.booked || false);
  }, [props.booked]);

  const onClick = () => {
    if (props.id && props.setSelected) props.setSelected(props.id);
    if (props.onClick) props.onClick();
  }

  const onBooking = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (!booked && props.id) {
      TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.push(props.id);
      setBooked(true);
      setButtonClicked(true);
      window.setTimeout(() => setButtonClicked(false), 3000);
    }
  }

  return (
    <div className="scene banner">
      <div className={`card ${buttonClicked ? 'flipped' : ''}`}>
        <div className="mission-card cardface" onClick={onClick}>
          <div className="wrapper" style={props.img ? {backgroundImage: `url(${props.img})`} : {}}>
            <div>
              <label className="banner-title" style={props.textColor ? {color: props.textColor} : {}}>{props.bannerTitle}</label>
              <label className="banner-text" style={props.textColor ? {color: props.textColor} : {}}>{props.bannerText}</label>
            </div>
            <div className="banner-icon">
              <FAIcon name={props.faName} brand={props.faBrand} solid={props.faSolid} style={props.textColor ? {color: props.textColor} : {}}/>
            </div>
          </div>
          <div className="content">
            <div className="labels">
              <label className="skill-name">Skill {props.skillName}</label>
              <label className="mission-name">{props.missionName}</label>
            </div>
            <button onClick={onBooking} className={`booking-button ${booked ? 'booked' : ''}`}><FAIcon name={`${booked ? 'check-circle' : 'plus-circle'}`} solid /></button>
          </div>
        </div>
        <div className="backside cardface">
          Mission Added <br /> to Profile
          <div className={buttonClicked ? 'show' : ''}><FAIcon name="check-circle"/></div>
        </div>
      </div>
    </div>
  );
}