import React from "react";
import {Container} from "react-bootstrap";

interface EventsProps {
    title: string,
    date: string,
    location: string,
    pic: string
}

const AllEvents: React.FC<EventsProps> = (props: EventsProps) => {
    return (
        <Container className={'event-card'}>
            <div className={'one-event-container'}>
                <div className="event-icon">
                    <img src={props.pic} alt="bag" className="event-pic"/>
                </div>
                <div className={'event-content'}>
                    <div>
                        <h4 className="event-title">{props.title}</h4>
                    </div>
                    <div className="event-date">{props.date}</div>
                    <div className="event-location">{props.location}</div>
                </div>
            </div>
        </Container>
    )
}

export default AllEvents;
