import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';

import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CompetenceListItem } from '../../../components/competence-list-item/CompetenceListItem';
import { CreatorPlug } from '../../../components/creator-plug/CreatorPlug';
import { FAIcon } from '../../../components/fa-icon/FAIcon';
import { calcMissionScore } from '../../../components/helpers';
import { SkillProgressBar } from '../../../components/skill-progress-bar/SkillProgressBar';
import { Mission, Lesson, TEST_MISSIONS, setSkillScoreFromMission } from '../../test-data';
import { Topbar } from '../../topbar/Topbar';

import './budgetbasic.scss';
import Cookies from "universal-cookie";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const checkPNG = require('../../../img/check-icon.png').default;
const playPNG = require('../../../img/playbutton.png').default;
const ladyOverview = require('../../../img/lady-lesson-overview.png').default;
const deniseMerz = require('../../../img/denise.png').default;
const followButton = require('../../../img/follow-button.png').default;
const PADLOCK = require('../../../img/padlock.svg').default;

const avgMark = require('../../../img/temporary-img/Avg.mark.png').default;
const finishedMissions = require('../../../img/temporary-img/Finished missions.png').default;
const latestMarks = require('../../../img/temporary-img/Latest marks.png').default;
let lessontitleclass = "";

const mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};

interface Props {
}

export const BudgetBasicPage: React.FC<Props> = (props: Props) =>  {

    const cookies = new Cookies();
    function getCookie1() {
        const cookie1 = cookies.get('course-1')
        if (cookie1){
            lessontitleclass = "lesson-done"
        } else {
            lessontitleclass = "lesson"
        }
        console.log(cookie1)
        return lessontitleclass
    }
    function getCookie2() {
        const cookie2 = cookies.get('course-2');
        if (cookie2){
            lessontitleclass = "lesson-done"
        } else {
            lessontitleclass = "lesson"
        }
        console.log(cookie2)
        return lessontitleclass
    }
    function getCookie3() {
        const cookie3 = cookies.get('course-3');
        if (cookie3){
            lessontitleclass = "lesson-done"
        } else {
            lessontitleclass = "lesson"
        }
        console.log(cookie3)
        return lessontitleclass
    }

    function getAllCookies() {
        getCookie1();
        getCookie2();
        getCookie3();
    }

  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="budgetbasic-container" onLoad={getAllCookies}>
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Lesson Overview`} subText={`Budget Basic level nu. basic`}/>
        <div className="main-container">
            <div className="container-up">
                <div className="course-overview-container" >
                    <div className="competence">
                        <h3 className="competence__title">Mission Progress | 20%</h3>
                        <div className="competence__progress">
                            <ProgressBar progress={20} max={100} percentage color="#FFF"/>
                        </div>

                        <div className="course-description">
                            <div className="course-description__title">Budget Basic </div>
                            <div className="course-description__section">
                                <div className="course-description__subtitle">Situation</div>
                                <div className="course-description__content">Endlich - der eigene Lohn! Pablo möchte sich ein neues Handy kaufen und im Sommer mit seinem Kollegen Snap zwei Wochen in die Ferien fahren ohne sich zu verschulden. Pablo träumt ausserdem von einem Sprachaufenthalt im Ausland nach der Lehre. Budgetieren ist angesagt!</div>
                            </div>
                            <div>
                                <div className="course-description__subtitle">Kompetenzbereich -</div>
                                <div className="course-description__content">Geld und Konsum</div>
                            </div>
                            <div className="course-description__section">
                                <div className="course-description__subtitle">Richtzeit</div>
                                <div className="course-description__content">16-20h</div>
                            </div>
                            <div className="course-description__image"><img className="ladyImg" src={ladyOverview} alt="ladyOverview"/></div>              
                        </div>
                    </div>

                </div>
                
                <div className="level-stack-container">
                    <div className="course-level-stack-container">
                        <div className="nu-skill-coverage">
                            <h3 className="title-skill-coverage" style={{ fontSize: 24, fontWeight: 500}}>My Skill Coverage</h3>
                            <SubCompetenceCourseOverviewNoBackground name="Mission Progress | 20% " scoreProgress={20}/>
                            <div className="nu-skill-coverage__criteria">
                                <h3 className="nu-skill-coverage__title">What Will I Learn?</h3>
                                <LessonCriteria text= "Sie können finanzielle Entscheidungen aufgrund einer realistischen Lagebeurteilung der jetzigen und zukünftigen finanziellen Situation treffen und diese auch begründen."/>
                                <LessonCriteria text= "Sie können die Gefahr der Verschuldung erkennen und in Ihre finanziellen Entscheidungen einbeziehen."/>
                                <LessonCriteria text= "Sie können den Sinn und Zweck eines Budgets erklären."/>
                                <LessonCriteria text= "Sie können den Aufbau eines Budgets erklären (Einnahmen und Ausgaben, freier Betrag)."/>
                                <LessonCriteria text= "Sie können den Unterschied von fixen und variablen Kosten erklären."/>
                                <LessonCriteria text= "Sie können Ihre eigenen finanziellen Möglichkeiten realistisch einschätzen."/>
                                <LessonCriteria text= "Sie können die Aufgaben einer Budgetberatungsstelle beschreiben."/>
                                <LessonCriteria text= "Sie können Ihre Erkenntnisse zu einem Thema schriftlich festhalten, begründen und wenn nötig mit Beispielen ergänzen."/>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            


            <div className="lessons-container">
                <div className="lessons-stack-container">
                    <div className="lessons">
                        <h3 className="lessons__stack-title" style={{ fontSize: 24, fontWeight: 500}}>Mission Content</h3>
                        <Author name="by Denise Merz" coursesFollowers="7 courses | 2894 Followers" img={deniseMerz}/>

                        <div className="nu-skill-coverage__criteria">
                            <a className="lesson" href="/budgetbasic/kompetenznachweiss">
                                <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
                                <div>
                                    <div className={getCookie1()}>01. Kompetenznachweis</div>
                                    <div className="lesson__duration">15m</div>
                                </div>
                            </a>
                            <a className="lesson" href="/budgetbasic/lernstand">
                                <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
                                <div>
                                    <div className={getCookie2()}>02. Lernstand 1</div>
                                    <div className="lesson__duration">1h</div>
                                </div>
                            </a>
                            <a className="lesson" href="/budgetbasic/nationalbank">
                                <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
                                <div>
                                    <div className={getCookie3()}>03. Nationalbank</div>
                                    <div className="lesson__duration">15m</div>
                                </div>
                            </a>
                            <BluredListLesson title="04. Variable und Fixkosten erlernen" duration="30m" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="05. Wissen 1" duration="30m" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="06. Wissen 2" duration="30m" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="07. Austausch" duration="30m" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="08. Lernstand 2" duration="1:30h" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="09. Üben 1" duration="1h" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="10. Üben 2" duration="1:40h" missionUrl="/mission/3/1"/>
                            <BluredListLesson title="11. Üben 3" duration="1:10h" missionUrl="/mission/3/1"/>
                        </div>
                    </div>
                </div>
            </div>    
        </div>   
        
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')
  return (
    <div className="top-bar">
      <div className="top-bar__text">
        <span className="top-bar__text--main">{props.mainText}</span>
        <span className="top-bar__text--sub">{props.subText}</span>
      </div>
        <div className="top-bar__nav">
            <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
            <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
            <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
        </div>
    </div>
  );
}


interface SubCompetenceCourseOverviewNoBackgroundProps {
    name: string;
    scoreProgress: number;
  }
  
export const SubCompetenceCourseOverviewNoBackground: React.FC<SubCompetenceCourseOverviewNoBackgroundProps> = (props: SubCompetenceCourseOverviewNoBackgroundProps) =>  {
    return (
        <div className="course-competence">
        <h3 className="course-competence__title">{props.name}</h3>
        <div className="course-competence__progress">
            <ProgressBar progress={props.scoreProgress} max={100} percentage color="#E17FC2"/>
        </div>
        </div>
    );
}

interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}

interface LessonCriteriaProps {
    text: string;
  }
  
export const LessonCriteria: React.FC<LessonCriteriaProps> = (props: LessonCriteriaProps) => {
    return (
        <div className="lesson-criteria">
            <div className="lesson-criteria__check"><img className="checkImg" src={checkPNG} alt="check"/></div>
            <div className="lesson-criteria__description">{props.text}</div>
        </div>
    );
}

interface ListLessonProps {
    title: string;
    duration: string;
    missionUrl: string;
}
  
export const ListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <a className="lesson" href={props.missionUrl}>
            <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__title">{props.title}</div>
                <div className="lesson__duration">{props.duration}</div>
            </div>
        </a>
    );
}

export const BluredListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <div className="lesson">
            <div className="lesson__blured-play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__blured-title">{props.title}</div>
                <div className="lesson__blured-duration">{props.duration}</div>
            </div>
        </div>
    );
}

interface AuthorProps {
    name: string;
    coursesFollowers: string;
    img: string;
}
  
export const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            <div className="author__profile-picture">
                <img className={props.img} src={props.img} alt={props.img}/>
            </div>
            <div className="author__description">
                <span className="name">{props.name}</span>
                <span className="courses-and-followers">{props.coursesFollowers}</span>
            </div>
        </div>
    );
}
