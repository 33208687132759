import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import { keycloak } from '../../api/login';
import { FAIcon } from '../../components/fa-icon/FAIcon';

import './topbar.scss';

interface NavElement {
  linkTo: string;
  pageName: string;
}

interface Props {

}


export const Topbar = React.forwardRef((props, ref) => {
  const [navElems, setNavElems] = React.useState<NavElement[]>([]);

  React.useImperativeHandle(ref, () => ({
    getNavElements: () => {
      return navElems;
    },

    setNavElements: (elms: NavElement[]) => {
      setNavElems(elms);
    }
  }));
  
  const onNavElemClick =  (linkTo: string, pageName: string) => {
    const index = navElems.findIndex(e => e.linkTo === linkTo && e.pageName === pageName);
    const newNavElems = navElems.slice(0, index);
    
    setNavElems(newNavElems);
  }
  
  return (
    <nav className="topbar">
      {navElems.map(navElem => (
        <NavElement linkTo={navElem.linkTo} pageName={navElem.pageName} onClick={onNavElemClick}/>
      ))}
      <div className="user-info">{keycloak.user.fullName}</div>
    </nav>
  );
});

interface NavProps {
  linkTo: string;
  pageName: string;
  onClick: (linkTo: string, pageName: string) => void;
}

const NavElement: React.FC<NavProps> = (props) => {
  const onClick = () => {
    props.onClick(props.linkTo, props.pageName);
  }

  return (
    <div className="nav-element">
      <Link to={props.linkTo} onClick={onClick}>
        <div>
          <FAIcon name="angle-left" solid/>
          {props.pageName}
        </div>
      </Link>
    </div>
  );
}