import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CompetenceListItem } from '../../components/competence-list-item/CompetenceListItem';
import { CreatorPlug } from '../../components/creator-plug/CreatorPlug';
import { FAIcon } from '../../components/fa-icon/FAIcon';
import { calcMissionScore } from '../../components/helpers';
import { SkillProgressBar } from '../../components/skill-progress-bar/SkillProgressBar';
import { Mission, Lesson, TEST_MISSIONS, setSkillScoreFromMission } from '../test-data';
import { Topbar } from '../topbar/Topbar';

import './mission-overview.scss';

interface Props {
  nav: React.Ref<typeof Topbar>
}

const AVATAR = require('../../img/denise.png').default;
const PLAY = require('../../img/play.svg').default;
const PADLOCK = require('../../img/padlock.svg').default;

export const MissionOverview: React.FC<Props> = (props) => {
  const [lessons, setLessons] = React.useState<Lesson[]>([]);
  const [fadeIn, setFadeIn] = React.useState<boolean>();
  const [lessonOpened, setLessonOpened] = React.useState<number>(0);
  const [lessonViewerOpened, setLessonViewerOpened] = React.useState<boolean>(false);

  const params = useParams<{missionId: string, lessonId: string}>();

  React.useEffect(() => {
    window.setTimeout(() => setFadeIn(true), 0);
    fillLessonList();
    if (params.lessonId) {
      openLesson(Number(params.lessonId));
      
      const mission = TEST_MISSIONS.find(mission => mission.id === Number(params.missionId));
      if (mission) {
        // update lesson score
        const lesson = mission.lessons.find(lesson => lesson.id === Number(params.lessonId));
        if (lesson?.score === 0) lesson.score = Math.round(Math.random() * (101 - 80) + 80);
        if (lesson) lesson.completed = true;
        
        // update mission score
        mission.score = calcMissionScore(mission.id);
        
        // update skill associated with current mission
        setSkillScoreFromMission(mission);
      }
    }
    else {
      setLessonOpened(0);
      setLessonViewerOpened(false);
    }
  }, [params.missionId, params.lessonId, params]);

  React.useEffect(() => {
    if (lessonViewerOpened && (props.nav as any)?.current) {
      (props.nav as any).current.setNavElements([...(props.nav as any).current.getNavElements(), {linkTo: `/mission/${params.missionId}`, pageName: 'Mission Overview'}]);
    }
  }, [lessonViewerOpened]);

  const fillLessonList = (index: number = 1) => {
    const mission = TEST_MISSIONS.find(mission => mission.id === Number(params.missionId)) || {} as Mission;

    setLessons(mission.lessons.slice(0, index));

    if (mission.lessons.length >= index) {
      window.setTimeout(() => fillLessonList(index + 1), 75);
    }
  }

  const openLesson = (id: number) => {
    setLessonOpened(id);
    window.setTimeout(() => setLessonViewerOpened(true), 500);
  }

  const mission = TEST_MISSIONS.find(mission => mission.id === Number(params.missionId)) || {} as Mission;

  return (
    <section className={`mission-overview ${fadeIn ? 'show' : ''}`}>
      <div className={`mission-overview__info ${lessonOpened ? 'hide' : ''}`}>
        <div className="mission-details">
          <h1>Mission {mission.name}</h1>
          <div className="mission-details__wrapper">
            <img className="mission-details__wrapper--visual" src={mission.img} alt="mission"/>
            <div className="mission-details__wrapper--text">
              {mission.description}
            </div>
          </div>
          <div className="mission-details__credits">
            <div>Mission Score {mission.score}%   {(mission.score || 0) >= 80 ? <FAIcon solid name="check" /> : ''}</div>
            <div><FAIcon name="eye"/> {mission.views}      <FAIcon name="clock" /> {mission.duration}</div>
            {/* <div><ProgressBar color="#007bff" progress={mission.score || 0}/></div> */}
          </div>
        </div>
        <div className="mission-coverage">
          <h3>My Skill Coverage</h3>
          <SkillProgressBar progress={mission.skill.score || 0} skill={mission.skill.name} color={mission.skill.color}/>
          <h4>What Will I Learn?</h4>
          <ul className="mission-coverage__competences">
            {mission.competences.map(competence => (
              <CompetenceListItem>
                {competence}
              </CompetenceListItem>
            ))}
          </ul>
          <div className="mission-coverage__additional-info">
            <div>
              <div>Language</div>
              <div>{mission.language}</div>
            </div>
            <div>
              <div>Expected time to complete</div>
              <div>{mission.duration}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`mission-overview__lessons ${lessonOpened ? 'minimize' : ''} ${lessonViewerOpened ? 'break-out' : ''}`}>
        <div className={`top ${lessonOpened ? 'hide' : ''}`}>
          <h1>Mission content</h1>
          <CreatorPlug name={`${mission.author.firstName} ${mission.author.lastName}`} followers={mission.author.followers.toString()} courses={mission.author.courses.toString()} avatar={AVATAR}/>
          <div className="lesson-count">{lessons.length} Lessons</div>
        </div>
          {lessons.map((lesson, i) => (
              <MissionLesson id={lesson.id} minimize={lessonOpened} linkTo={`/mission/${params.missionId}/${lesson.id}`} number={lesson.number} name={lesson.name} duration={lesson.duration} locked={lesson.locked} score={lesson.score} completed={lesson.completed}/>
          ))}
      </div>
      <div className={`lesson-viewer ${lessonViewerOpened ? 'show' : ''}`}>
          <iframe className={`lesson-frame`} src={mission.lessons.find(lesson => lesson.id === lessonOpened)?.lessonLink} title="lesson"/>
      </div>
    </section>
  );
}

interface LessonProps {
  id: number;
  name: string;
  number: string;
  duration: string;
  locked?: boolean;
  minimize: number;
  linkTo: string;
  score?: number;
  completed?: boolean;
}

const MissionLesson: React.FC<LessonProps> = (props) => {
  const [animated, setAnimated] = React.useState<boolean>(false);
  const [completed, setCompleted] = React.useState<boolean>(false);

  const location = useLocation();

  React.useEffect(() => {
    if (animated) {
      window.setTimeout(() => setAnimated(false), 500);
    }
  }, [animated]);

  React.useEffect(() => {
    if ((props.score && props.score >= 80) || props.completed) {
      setCompleted(true);
    }
  }, [props.completed, props.score]);

  return (
    <Link to={props.locked ? location.pathname : props.linkTo} className="lesson-link">
      <div className={`lesson ${props.minimize ? 'minimize' : ''} ${props.minimize === props.id ? 'highlight' : ''}`} title={props.name} onMouseDown={() => setAnimated(true)}>
        <div className={`${props.locked ? 'locked' : 'unlocked'} ${animated ? 'animated' : ''}`}>
          <img src={props.locked ? PADLOCK : PLAY} alt="lesson status"/>
        </div>
        <div className={completed ? 'strike' : ''}>
          {props.number}{'. ' + props.name}
        </div>
        <div>
          {Boolean(props.score && props.score !== 0) && `${props.score}%`}
        </div>
        {!props.minimize &&
          <div>
            {props.duration}
          </div>
        }
      </div>
    </Link>
  );
}
