import './latestNotesItem.scss';
import moment from "moment";

interface NoteProps {
    date: Date;
    text: string;
}

export const Note: React.FC<NoteProps> = (props: NoteProps) => {
    return (
        <div className="note">
            <h4 className="note__date">{moment(props.date).format('dddd, HH:mm')}</h4>
            <div className="note__content">
        <span>
          {props.text}
            <span className="underlines"/>
        </span>
            </div>
        </div>
    );
}