import React from 'react';
import moment from 'moment';

import './chat.scss';

interface TimestampProps {
  timestamp: Date;
}

export const Timestamp: React.FC<TimestampProps> = (props) => {
  return (
    <div className="timestamp">{moment(props.timestamp).format('DD MMM YYYY')}</div>
  );
}

interface MessageProps {
  message: string;
  timestamp: Date;
  backgroundColor?: string;
  color?: string;
  alignRight?: boolean;
}

export const Message: React.FC<MessageProps> = (props) => {
  return (
    <div className="message__wrapper">
      <div className="content" style={{marginLeft: props.alignRight ? 'auto' : 0}}>
        <div className="message__text" style={{backgroundColor: props.backgroundColor || 'white', color: props.color || '#404040'}}>
          {props.message}
        </div>
        <div className="message__timestamp">
          {moment(props.timestamp).format('LT')}
        </div>
      </div>
    </div>
  );
}

export const OwnMessage: React.FC<MessageProps> = (props) => {
  return (
    <Message backgroundColor="#007bff" color="white" alignRight {...props}/>
  );
}