import Keycloak from "keycloak-js";

interface KeycloakUser {
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  fullName: string;
  preferredUsername: string;
  sub: string;
}

interface KeycloakResources {
  instance: Keycloak.KeycloakInstance;
  user: KeycloakUser;
}

export const keycloak: KeycloakResources = {
  instance: {} as Keycloak.KeycloakInstance,
  user: {} as KeycloakUser
}

export const AuthAPI = {
  async login(): Promise<boolean> {
    keycloak.instance = Keycloak({
      realm: 'quarkus',
      url: 'https://auth.cleoag.dev/auth',
      clientId: 'frontend',
    });

    await keycloak.instance.init({
      onLoad: 'login-required',
    })
    
    const info = await keycloak.instance.loadUserInfo() as any;

    keycloak.user = {
      email: info.email,
      emailVerified: info.email_verified,
      familyName: info.family_name,
      givenName: info.given_name,
      fullName: info.name,
      preferredUsername: info.preferred_username,
      sub: info.sub,
    }

    return true;
  },

  logout() {
    localStorage.clear();
    keycloak.instance.logout();

    keycloak.instance = {} as Keycloak.KeycloakInstance;
    keycloak.user = {} as KeycloakUser;
  }
}