import React, { CSSProperties } from 'react';

interface Props {
  name: string;
  solid?: boolean;
  brand?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

// Helper component for font awesome icons
export const FAIcon: React.FC<Props> = (props) => {
  let iconStyle = 'r';
  if (props.solid) iconStyle = 's';
  if (props.brand) iconStyle = 'b';

  return (
    <i style={props.style} onClick={props.onClick} className={`fa${iconStyle} fa-${props.name} ${props.className}`}></i>
  );
}