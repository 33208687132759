import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {MissionOverview} from './mission-overview/MissionOverview';
import {LXPRoute} from './LXPRoute';
import {Home_Old} from './home-old/Home_Old';
import {ProfileOverview} from './profile-overview/ProfileOverview';
import {Chat} from './chat/Chat';
import {Explore} from './explore/Explore';
import {Social} from './social/Social';
import {initialiseMockup, TEST_MISSIONS} from './test-data';
import {Topbar} from './topbar/Topbar';
import {AuthAPI, keycloak} from '../api/login';
import {Home} from './home/Home';

import {Subcompetences} from './subcompetences/subcompetences';
import {AllgemeinbildungPage} from './demo-pages/competences/allgemeinbildung';
import {BerufsKundePage} from './demo-pages/competences/berufskunde';
import {UeberbetrieblicheKursePage} from './demo-pages/competences/ueberbetrieblichekurse';
import {GeldUndKonsumPage} from './demo-pages/subcompetences/geldundkonsum';
import {BudgetBasicPage} from './demo-pages/courses/budgetbasic';
import {StoreLehrgaenge} from './demo-pages/shop/storelehrgaenge';
import {KompetenznachweisPage} from './demo-pages/lessons/kompetenznachweiss';
import {AllgHandlungskompetenzenPage} from './demo-pages/subcompetences/allghandlungskompetenzen';
import {BewirtschaftenPage} from './demo-pages/subcompetences/bewirtschaften';
import {LernstandPage} from './demo-pages/lessons/lernstand';
import {NationalbankPage} from './demo-pages/lessons/nationalbank';
import {StoreIndivWeiterbildung} from './demo-pages/shop/indivweiterbildung';
import {StoreLernmaterial} from './demo-pages/shop/lernmaterial';
import {MyProfile} from "./demo-pages/myprofile/MyProfile";
import {LatestNotes} from "./demo-pages/latest-notes/LatestNotes"

import './lxp-router.scss';
import EventsPage from "./demo-pages/events/EventsPage";


export const LXPRouter: React.FC = (props) => {
    const [loggedIn, setLoggedIn] = React.useState<boolean>(Boolean(keycloak?.instance?.token));
    const navRef = React.createRef<typeof Topbar>();

    React.useEffect(() => {
        if (!loggedIn) startup();
    }, [loggedIn]);

    const startup = async () => {
        initialiseMockup();
        const isLoggedIn = await AuthAPI.login();

        setLoggedIn(isLoggedIn);
    };

    const commonProps = {exact: true, navRef};

    return (
        <BrowserRouter>
            <div className="router">
                {loggedIn &&
                <Switch>
                    {/* TODO Delete old LXPRoutes after implementing new Routes */}
                    <LXPRoute path="/mission/:missionId/:lessonId?" {...commonProps}>
                        <MissionOverview nav={navRef}/>
                    </LXPRoute>
                    <LXPRoute path="/profile" {...commonProps}>
                        <ProfileOverview nav={navRef}/>
                    </LXPRoute>
                    <LXPRoute path="/chat" {...commonProps}>
                        <Chat/>
                    </LXPRoute>
                    <LXPRoute path="/home-old" {...commonProps}>
                        <Home_Old nav={navRef}/>
                    </LXPRoute>
                    <LXPRoute path="/explore" {...commonProps}>
                        <Explore missions={TEST_MISSIONS}/>
                    </LXPRoute>
                    <LXPRoute path="/social" {...commonProps}>
                        <Social/>
                    </LXPRoute>


                    <Route path="/home" {...commonProps}>
                        <Home/>
                    </Route>

                    {/* TODO NULXP-1 */}
                    <Route path="/subcompetences/all" {...commonProps}>
                        <Subcompetences/>
                    </Route>

                    <Route path="/competence/allgemeinbildung" {...commonProps}>
                        <AllgemeinbildungPage/>
                    </Route>
                    <Route path="/competence/berufskunde" {...commonProps}>
                        <BerufsKundePage/>
                    </Route>
                    <Route path="/competence/ueberbetrieblichekurse" {...commonProps}>
                        <UeberbetrieblicheKursePage/>
                    </Route>
                    <Route path="/subcompetences/geldundkonsum" {...commonProps}>
                        <GeldUndKonsumPage/>
                    </Route>
                    <Route path="/lesson/allgemeinbildung/budgetbasic" {...commonProps}>
                        <BudgetBasicPage/>
                    </Route>
                    <Route path="/budgetbasic/lernstand" {...commonProps}>
                        <LernstandPage/>
                    </Route>
                    <Route path="/budgetbasic/nationalbank" {...commonProps}>
                        <NationalbankPage/>
                    </Route>
                    <Route path="/budgetbasic/kompetenznachweiss" {...commonProps} >
                      <KompetenznachweisPage/>
                    </Route>

                    <Route path="/competences/allghandlungskompetenzen" {...commonProps}>
                        <AllgHandlungskompetenzenPage/>
                    </Route>
                    <Route path="/competences/allghandlungskompetenzen/skill/bewirtschaften" {...commonProps}>
                        <BewirtschaftenPage/>
                    </Route>

                    <Route path="/store/all" {...commonProps}>
                        <StoreLehrgaenge/>
                    </Route>
                    <Route path="/store/indivweiterbildung" {...commonProps}>
                        <StoreIndivWeiterbildung/>
                    </Route>
                    <Route path="/store/lernmaterial" {...commonProps} >
                        <StoreLernmaterial/>
                    </Route>

                    <Route path="/myprofile" {...commonProps} >
                        <MyProfile/>
                    </Route>

                    <Route path="/yournotes" {...commonProps} >
                        <LatestNotes/>
                    </Route>

                    <Route path="/events" {...commonProps} >
                        <EventsPage/>
                    </Route>

                    <Redirect to="/home"/>
                </Switch>
                }
            </div>
        </BrowserRouter>
    );
}