import React from 'react';
import { Avatar } from '../../components/avatar/Avatar';
import { Comment } from '../../components/comment/Comment';
import { FAIcon } from '../../components/fa-icon/FAIcon';
import { HeartButton } from '../../components/heart-button/HeartButton';

import './social.scss';

const AVATAR = require('../../img/test_avatar.png').default;
const POST1 = require('../../img/post_01.jpg').default;
const POST2 = require('../../img/post_02.jpg').default;

interface Props {

}

export const Social: React.FC<Props> = (props) => {
  const [selectedPost, setSelectedPost] = React.useState<number | null>(null);

  return (
    <section className="social">
      <div className="left">
        <h1>Social</h1>
        <Post text="This is the first social post on this LXP! :)" img={POST1} name="Eleanor Spring" id={1} setSelected={setSelectedPost} selectedPost={selectedPost}/>
        <Post text="Yet another post on this LXP. This ones text is a bit longer though, because this way we can better check the CSS." img={POST2} name="Eleanor Spring" id={2} setSelected={setSelectedPost} selectedPost={selectedPost}/>
        <Post text="This example post does not have an image. It is purely text based. That's why this text is so unnecessarily long" name="Eleanor Spring" id={3} setSelected={setSelectedPost} selectedPost={selectedPost}/>
        <Post img={POST2} name="Eleanor Spring" id={4} setSelected={setSelectedPost} selectedPost={selectedPost}/>
      </div>
      <div className={`right ${selectedPost ? 'show' : 'hide'}`}>
        <h1><FAIcon name="angle-left" solid className="back-button" onClick={() => setSelectedPost(null)}/> Comments</h1>
        <div className="comment-section">
          <Comment img={AVATAR} name="Aaron Kromer" date={new Date('2021-01-05')} comment="What an inspiring post Eleanor! I love your content. Please keep us updated on your future progress. It would really mean a lot <3"/>
          <Comment img={AVATAR} name="Brandon Schenk" date={new Date('2021-01-04')} comment="I'm really excited to see CulmenLXP grow and prosper!"/>
        </div>
      </div>
    </section>
  );
}

interface PostProps {
  id: number;
  name: string;
  img?: string;
  text?: string;
  selectedPost: number | null;
  setSelected: (value: number | null) => void;
}

const Post: React.FC<PostProps> = (props) => {
  const [isFav, setIsFav] = React.useState<boolean>(false);
  
  const selectPost = () => {
    props.setSelected(props.id === props.selectedPost ? null : props.id);
  }

  const selected = props.id === props.selectedPost;

  return (
    <div className="post">
      <div className="post__content">
        <div className="post__creator">
          <div>
            <Avatar img={AVATAR}/>
            <div className="name">{props.name}</div>
          </div>
          <div>
            <FAIcon className="menu" name="ellipsis-v" solid/>
          </div>
        </div>
        {props.text && <div className="post__text">{props.text}</div>}
        {props.img && <img className="post__img" src={props.img} alt="Post Content"/>}
      </div>
      <div className="post__interaction">
        <div>
          <HeartButton size="30px"/>
        </div>
        <div>
          <FAIcon name="comment" className={`post__interaction__comment ${selected ? 'active' : ''}`} solid={selected} onClick={selectPost}/>
        </div>
        <div>
          <FAIcon name="bookmark" className={`fav ${isFav ? 'active' : ''}`} solid={isFav} onClick={() => setIsFav(!isFav)}/>
        </div>
      </div>
    </div>
  );
}