import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';

import './indivweiterbildung.scss';
import  StorePopUp from './storepopup';
import IndivWeiterbildungPopUp from './indivweiterpopup';
import {keycloak} from "../../../api/login";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const checkPNG = require('../../../img/check-icon.png').default;
const playPNG = require('../../../img/playbutton.png').default;
const ladyOverview = require('../../../img/lady-lesson-overview.png').default;
const deniseMerz = require('../../../img/denise.png').default;
const followButton = require('../../../img/follow-button.png').default;
const PADLOCK = require('../../../img/padlock.svg').default;
const filterIcon = require('../../../img/filter-search-icon.png').default;

const wordLogo = require("../../../img/word-logo.png").default;
const excelLogo = require("../../../img/excel-logo.png").default;
const powerpointLogo = require("../../../img/powerpoint-logo.png").default;
const accessLogo = require("../../../img/access-logo.png").default;
const outlookLogo = require("../../../img/outlook-logo.png").default;

interface Props {

}

export const StoreIndivWeiterbildung: React.FC<Props> = (props: Props) =>  {

  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="indivweiterbildung-container">
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Store`} subText={`Welcome to the NU Store`}/>
        <div className="main-container">
          <div className="container-up">
            {/*<div className="top-bar-store">
              <div className="store-sections">
              
                <a className="store-sections__section-button store-sections__lehrgaenge" href="/store/lehrgaenge">
                  <div>Lehrgänge</div> 
                </a>
                <a className="store-sections__section-button store-sections__indivweiterbildung" href="#">
                  <div>Individuelle Weiterbidung</div>
                </a>
                <a className="store-sections__section-button store-sections__lernmaterial" href="/store/lernmaterial">
                  <div>Lernmaterial</div>
                </a>
              </div>
              <div className="search-components">
                <div className="search-bar-store">
                  <input type="text"/>
                </div>
                <div className="search-filter">
                <div><img src={filterIcon} /></div>

                </div>
              </div>
            </div>*/}

            <div className="store-stack-container">
                <div className="store-content-stack-container">
                    <div className="store-content">
                      <h3 className="title">Individuelle Weiterbildung</h3>
                      <div className="store-items">
                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <img className="store-img-content" src={wordLogo} />
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Word</div>
                              <div className="item-type">
                                <div>Indiv. Weiterbildung</div>
                              </div>
                              <div className="item-description">Klub Schule Migros</div>
                              <div className="item-level">Beginner</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <IndivWeiterbildungPopUp 
                              iconURL = {wordLogo}
                              backgroundColor = {'background: linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}
                              buttonBefore = {'Book'}
                              buttonAfter = {'Booked'}
                              itemTitle = {'Word'}
                              publisher = {'Klub Schule Migros'}
                              lastUpdated = {'20/09/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Word Kurs & Diplom'}
                              itemCompetenceArea = {'Indiv. Weiterbildung'}
                              estimatedTime = {'12 Lektionen'}
                              descriptionTitle = {'Word - Diplom'}
                              itemDescription1 = {'Lernen Sie den richtigen Umgang mit Microsoft Word noch effizienter, indem Sie selbst entscheiden wann, wo und was Sie lernen möchten. In den Online Word-Kursen lernen Sie in Ihrem eigenen Tempo und üben direkt in der virtuellen Office-Anwendung, welche Feedback zu Ihren Resultaten in Echtzeit liefert.'}
                              itemDescription2 = {''}
                              itemDescription3 = {''}
                              itemDescription4 = {''}
                              itemDescription5 = {''}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <img className="store-img-content" src={excelLogo} />
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Excel</div>
                              <div className="item-type">
                                <div>Indiv. Weiterbildung</div>
                              </div>
                              <div className="item-description">Klub Schule Migros</div>
                              <div className="item-level">Beginner</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <IndivWeiterbildungPopUp 
                              iconURL = {excelLogo}
                              backgroundColor = {'background: linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}
                              buttonBefore = {'Book'}
                              buttonAfter = {'Booked'}
                              itemTitle = {'Excel'}
                              publisher = {'Klub Schule Migros'}
                              lastUpdated = {'20/09/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Excel Kurs & Diplom'}
                              itemCompetenceArea = {'Indiv. Weiterbildung'}
                              estimatedTime = {'12 Lektionen'}
                              descriptionTitle = {'Excel - Diplom'}
                              itemDescription1 = {'Lernen Sie den richtigen Umgang mit Microsoft Excel noch effizienter, indem Sie selbst entscheiden wann, wo und was Sie lernen möchten. In den Online Excel-Kursen lernen Sie in Ihrem eigenen Tempo und üben direkt in der virtuellen Office-Anwendung, welche Feedback zu Ihren Resultaten in Echtzeit liefert.'}
                              itemDescription2 = {''}
                              itemDescription3 = {''}
                              itemDescription4 = {''}
                              itemDescription5 = {''}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <img className="store-img-content" src={powerpointLogo} />
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">PowerPoint</div>
                              <div className="item-type">
                                <div>Indiv. Weiterbildung</div>
                              </div>
                              <div className="item-description">Klub Schule Migros</div>
                              <div className="item-level">Beginner</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <IndivWeiterbildungPopUp 
                              iconURL = {powerpointLogo}
                              backgroundColor = {'background: linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}
                              buttonBefore = {'Book'}
                              buttonAfter = {'Booked'}
                              itemTitle = {'PowerPoint'}
                              publisher = {'Klub Schule Migros'}
                              lastUpdated = {'20/09/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'PowerPoint Kurs & Diplom'}
                              itemCompetenceArea = {'Indiv. Weiterbildung'}
                              estimatedTime = {'20 Lektionen'}
                              descriptionTitle = {'PowerPoint - Diplom'}
                              itemDescription1 = {'Wir geben Ihnen das Rüstzeug für gute Präsentationen. In den PowerPoint-Kursen zeigen wir Ihnen, wie Sie Informationen auf eine interessante und ansprechende Weise aufbereiten. Sie erfahren beispielsweise, wie Sie Folien, Diagramme und Organigramme erstellen und wie Sie WordArt, ClipArt und Multimedia einsetzen. Selbst Fotoalben können Sie mit PowerPoint problemlos gestalten. Wann beeindrucken Sie Ihr Publikum mit einer professionellen Präsentation?'}
                              itemDescription2 = {''}
                              itemDescription3 = {''}
                              itemDescription4 = {''}
                              itemDescription5 = {''}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <img className="store-img-content" src={accessLogo} />
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Access</div>
                              <div className="item-type">
                                <div>Indiv. Weiterbildung</div>
                              </div>
                              <div className="item-description">Klub Schule Migros</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <IndivWeiterbildungPopUp 
                              iconURL = {accessLogo}
                              backgroundColor = {'background: linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}
                              buttonBefore = {'Book'}
                              buttonAfter = {'Booked'}
                              itemTitle = {'Access'}
                              publisher = {'Klub Schule Migros'}
                              lastUpdated = {'20/09/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Access Kurs & Diplom'}
                              itemCompetenceArea = {'Indiv. Weiterbildung'}
                              estimatedTime = {'12 Lektionen'}
                              descriptionTitle = {'Access - Diplom'}
                              itemDescription1 = {'Sie haben Unmengen an Daten, wissen aber nicht, wie Sie diese am besten verwalten? Die Lösung: Legen Sie Datenbanken mit Hilfe von Access an. Mit diesem Programm können Sie umfangreiche Datensammlungen organisieren.'}
                              itemDescription2 = {'In den Access-Einsteigerkursen richten Sie zunächst eine Datenbankstruktur ein. Wir zeigen Ihnen anschliessend, wie Sie Daten importieren und exportieren. Sie lernen auch, mit gezielten Abfragen und Berichten die Zahlen auszuwerten. Selektieren, sortieren, gruppieren und verwalten – Sie haben Ihre Daten im Griff. In den Aufbaukursen werden Sie zum wahren Access-Experten: Mit dem neuen Wissen können Sie beispielsweise Abläufe steuern und automatisieren. Wann profitieren Sie von der Zeitersparnis durch die Datenverwaltung mit Access?'}
                              itemDescription3 = {''}
                              itemDescription4 = {''}
                              itemDescription5 = {''}
                            />
                          </div>
                        </div>

                        <div className="store-item__container">
                          <div className="store-item__container-up">
                            <div className="store-item__icon">
                              <img className="store-img-content" src={outlookLogo} />
                            </div>
                            <div className="store-item__description">
                              <div className="item-title">Outlook</div>
                              <div className="item-type">
                                <div>Indiv. Weiterbildung</div>
                              </div>
                              <div className="item-description">Klub Schule Migros</div>
                              <div className="item-level">Intermediate</div>
                              <div className="item-stars"></div>
                            </div>
                          </div>
                          <div className="store-item__container-down">
                            <div className="placeholder"></div>
                            <IndivWeiterbildungPopUp 
                              iconURL = {outlookLogo}
                              backgroundColor = {'background: linear-gradient(93.38deg, #0F67E5 -12.19%, #0C7BE3 47.26%, #1350AD 107.28%)'}
                              buttonBefore = {'Book'}
                              buttonAfter = {'Booked'}
                              itemTitle = {'Outlook'}
                              publisher = {'Klub Schule Migros'}
                              lastUpdated = {'20/09/2021'}
                              lessonsNmr = {'placeholder'}
                              itemMission = {'Outlook Kurs & Diplom'}
                              itemCompetenceArea = {'Indiv. Weiterbildung'}
                              estimatedTime = {'12 Lektionen'}
                              descriptionTitle = {'Outlook - Diplom'}
                              itemDescription1 = {'Woran denken Sie bei Outlook? An E-Mails? Richtig! Aber mit dem Programm können Sie viel mehr als E-Mails schreiben, senden und empfangen. Es ist auch ein effizientes Planungs- und Organisationsmittel. Als Management-Tool eignet es sich zudem, um Termine zu organisieren und Kontaktadressen zu verwalten. Mit Outlook können Sie auf gemeinsame Kalender zugreifen und Ihr Zeitmanagement optimieren. Nur wenige Klicks bei den Einstellungen und schon räumt sich Ihr Posteingang automatisch auf.'}
                              itemDescription2 = {'Neugierig? Wir zeigen Ihnen in unseren Kursen, wie Sie das Potenzial von Outlook ausschöpfen und für Ihre Bedürfnisse nutzen. So behalten Sie mit Outlook stets den Überblick.'}
                              itemDescription3 = {''}
                              itemDescription4 = {''}
                              itemDescription5 = {''}
                            />
                          </div>
                        </div>
                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>
                        <div className="store-item-container">

                        </div>


                      </div>

  
                        
                    </div>
                </div>
            </div>  
          </div> 
        </div>   
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}


interface SubCompetenceCourseOverviewNoBackgroundProps {
    name: string;
    scoreProgress: number;
  }
  
export const SubCompetenceCourseOverviewNoBackground: React.FC<SubCompetenceCourseOverviewNoBackgroundProps> = (props: SubCompetenceCourseOverviewNoBackgroundProps) =>  {
    return (
        <div className="course-competence">
        <h3 className="course-competence__title">{props.name}</h3>
        <div className="course-competence__progress">
            <ProgressBar progress={props.scoreProgress} max={100} percentage color="#E17FC2"/>
        </div>
        </div>
    );
}

interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}

interface LessonCriteriaProps {
    text: string;
  }
  
export const LessonCriteria: React.FC<LessonCriteriaProps> = (props: LessonCriteriaProps) => {
    return (
        <div className="lesson-criteria">
            <div className="lesson-criteria__check"><img className="checkImg" src={checkPNG} alt="check"/></div>
            <div className="lesson-criteria__description">{props.text}</div>
        </div>
    );
}

interface ListLessonProps {
    title: string;
    duration: string;
}
  
export const ListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <div className="lesson">
            <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__title">{props.title}</div>
                <div className="lesson__duration">{props.duration}</div>
            </div>
        </div>
    );
}

interface AuthorProps {
    name: string;
    coursesFollowers: string;
    img: string;
}
  
export const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            <div className="author__profile-picture">
                <img className={props.img} src={props.img} alt={props.img}/>
            </div>
            <div className="author__description">
                <span className="name">{props.name}</span>
                <span className="courses-and-followers">{props.coursesFollowers}</span>
            </div>
            <div className="author__follow-button">
                <img className="author-follow-button" src={followButton} alt="follow"/>
            </div>
        </div>
    );
}





interface LessonProps {
    id: number;
    name: string;
    number: string;
    duration: string;
    locked?: boolean;
    minimize: number;
    linkTo: string;
    score?: number;
    completed?: boolean;
}



const MissionLesson: React.FC<LessonProps> = (props) => {
    const [animated, setAnimated] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
  
    const location = useLocation();
  
    React.useEffect(() => {
      if (animated) {
        window.setTimeout(() => setAnimated(false), 500);
      }
    }, [animated]);
  
    React.useEffect(() => {
      if ((props.score && props.score >= 80) || props.completed) {
        setCompleted(true);
      }
    }, [props.completed, props.score]);
  
    return (
      <Link to={props.locked ? location.pathname : props.linkTo} className="lesson-link">
        <div className={`lesson ${props.minimize ? 'minimize' : ''} ${props.minimize === props.id ? 'highlight' : ''}`} title={props.name} onMouseDown={() => setAnimated(true)}>
          <div className={`${props.locked ? 'locked' : 'unlocked'} ${animated ? 'animated' : ''}`}>
            <img src={props.locked ? PADLOCK : playPNG} alt="lesson status"/>
          </div>
          <div className={completed ? 'strike' : ''}>
            {props.number}{'. ' + props.name}
          </div>
          <div>
            {Boolean(props.score && props.score !== 0) && `${props.score}%`}
          </div>
          {!props.minimize &&
            <div>
              {props.duration}
            </div>
          }
        </div>
      </Link>
    );
  }