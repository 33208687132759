import React from "react";
import {keycloak} from "../../../api/login";
import {useHistory} from "react-router-dom";
import  Sidebar from '../../../components/sidebar/Sidebar';
import moment from "moment";
import {Calendar} from "../../../components/calendar/Calendar";
import {ProgressBar} from "../../../components/progress-bar/ProgressBar";
import {AllgemeinbildungSubcompetences, SubCompetenceBlended} from "../../competence/Competence";
import './myProfile.scss';

// const profilePic = require('../../../img/temporary-img/jack-smith-picture.png').default;
const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const messageIcon = require('../../../img/message.svg').default;
const callIcon = require('../../../img/call.svg').default;

// import Calendar from 'react-calendar'

export const MyProfile: React.FC = () => {

    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="myprofile-container">
            <Sidebar />
            <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
                <TopBar mainText={`Mein Profil`} subText={``}/>

                    <div className="my-profile-container">
                        <div className="general-informations">
                        <h3 className="general-info-title">Allgemeine Informationen</h3>
                        <div>
                            {/*<img className="resize" src={profilePic} alt="profile-picture"/>*/}
                        </div>
                        <div>
                                <p className={'profile-name'}>{keycloak.user.fullName}</p>
                                <p className={'profile-id'}>Student ID nicht gesetzt.</p>
                            <div className="text-row">
                                <img className="call-icon" src={callIcon} alt="call-icon"/>
                                <p className={'profile-number'}>Telefonnummer nicht gesetzt.</p>
                            </div>
                            <div className= "text-row">
                                <img className="message-icon" src={messageIcon} alt="message-icon"/>
                                <p className={'profile-email'}>{keycloak.user.email}</p>
                            </div>
                        </div>
                    </div>
                        <div className="card-container">
                            <div className="about-me-card">
                            <p className={'about-me-title'}>About me</p>
                            <p className={'about-me-birthday'}>Geburtsdatum: nicht gesetzt</p>
                            <p className={'about-me-interests'}>Meine Interessen: nicht gesetzt</p>
                            <p className={'about-me-land'}>Land: nicht gesetzt</p>
                            <p className={'about-me-town'}>Stadt: nicht gesetzt</p>
                            <p className={'about-me-text'}>Über mich: nicht gesetzt</p>
                        </div>

                        <div className="contact-university-card">
                                <p className={'contact-university-title'}>Kontaktinformationen der Universität</p>
                                <p className={'contact-university-opening-times'}>Öffnungszeiten: nicht gesetzt</p>
                                <p className={'contact-university-management'}>Kontaktangaben der Hausverwaltung:
                                    nicht gesetzt</p>
                            <div className="contact-university-teachers">
                                <p className={'teacher-name'}>Lehrer: nicht gesetzt</p>
                                    <p className={'teacher-number-email'}>Telefon: nicht gesetzt | E-Mail: nicht gesetzt</p>
                            </div>
                                <p className={'contact-university-important-infos'}>Wichtige Informationen: nicht gesetzt</p>
                        </div>
                    </div>
                        </div>
                </div>


                </div>
    )

}

interface TopBarProps {
    mainText: string;
    subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}

export default MyProfile;