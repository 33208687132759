import moment from 'moment';
import React from 'react';
import { keycloak } from '../../api/login';
import { ProgressBar } from '../../components/progress-bar/ProgressBar';
import  Sidebar from '../../components/sidebar/Sidebar';

import './home.scss';
import {useHistory} from "react-router-dom";

const bagSVG = require('../../img/bag.svg').default;
const profileSVG = require('../../img/profile.svg').default;
const notificationSVG = require('../../img/notification.svg').default;
const editSVG = require('../../img/edit.svg').default;

const avgMark = require('../../img/temporary-img/Avg.mark.png').default;
const finishedMissions = require('../../img/temporary-img/Finished missions.png').default;
const latestMarks = require('../../img/temporary-img/Latest marks.png').default;

export const Home: React.FC = () =>  {
  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="home-container">
      <Sidebar />
      <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Hi, ${keycloak.user.fullName} 👋`} subText={`It’s currently ${moment().format('HH:mm')}, Let’s make this a Productive Day`}/>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '25px 50px 0 50px'}}>
          <LatestNotes/>
          <div className="statistics">
            <h3 className="statistics__title">Statistics</h3>
            <div className="avgMark-container">
              <span className="title-statistics">Avarage marks</span>
              <img src={avgMark} alt="" className="avgMark"/>
            </div>
            <div className="latestMarks-container">
              <span className="title-statistics">Latest marks</span>
              <img src={latestMarks} alt="" className="latestMarks"/>
            </div>
            <div className="finishedMissions-container">
              <span className="title-statistics">Finished missions</span>
              <img src={finishedMissions} alt="" className="finishedMissions"/>
            </div>
          </div>
        </div>
        <h3 style={{margin: '60px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Your Competences</h3>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <Competence name="Allgemeinbildung" skillProgress={0} missionProgress={0} scoreProgress={0} url="/competence/allgemeinbildung"/>
          <CompetenceBlended name="Allgem. Handlungskompeten." skillProgress={0} missionProgress={0} scoreProgress={0} url="/competence/berufskunde" backgroundColor={"linear-gradient(93.28deg, #46AD5C -13.74%, #22A8C6 59.62%, #199CB9 106.21%)"}/>
          <CompetenceBlended name="Überbetriebliche Kurse" skillProgress={0} missionProgress={0} scoreProgress={0} url="/competence/ueberbetrieblichekurse" backgroundColor={"linear-gradient(110.66deg, #EB7F20 -5.94%, #FEB14B 104.3%)"}/>
        </div>
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
    <div className="top-bar">
      <div className="top-bar__text">
        <span className="top-bar__text--main">{props.mainText}</span>
        <span className="top-bar__text--sub">{props.subText}</span>
      </div>
      <div className="top-bar__nav">
          <div className="top-bar__nav--elem" onClick={navigateToStore} ><img src={bagSVG} alt="bag"/></div>
          <div className="top-bar__nav--elem" onClick={navigateToProfile}><img src={profileSVG} alt="profile"/></div>
          <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
      </div>
    </div>
  );
}

export const LatestNotes: React.FC = () => {
  return (
    <div className="latest-notes">
      <div>
        <h3 className="latest-notes__title">Latest Notes</h3>
        <img src={editSVG} alt="edit"/>
      </div>
      <Note date={new Date('2021-04-06T12:31:00')} text="Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled i"/>
      <Note date={new Date('2021-04-11T14:52:00')} text="Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled i"/>
    </div>
  );
}

interface NoteProps {
  date: Date;
  text: string;
}

export const Note: React.FC<NoteProps> = (props: NoteProps) => {
  return (
    <div className="note">
      <h4 className="note__date">{moment(props.date).format('dddd, HH:mm')}</h4>
      <div className="note__content">
        <span>
          {props.text}
          <span className="underlines"/>
        </span>
      </div>
    </div>
  );
}

interface CompeteceProps {
  name: string;
  skillProgress: number;
  missionProgress: number;
  scoreProgress: number;
  url: string;
}

export const Competence: React.FC<CompeteceProps> = (props: CompeteceProps) =>  {
  return (
    <a className="competence" href={props.url}>
      <h3 className="competence__title">{props.name}</h3>
      <div className="competence__progress">
        <ProgressBar progress={props.skillProgress} max={24} text="skills" color="#FFF"/>
        <ProgressBar progress={props.missionProgress} max={48} text="missions" color="#FFF"/>
        <ProgressBar progress={props.scoreProgress} max={100} text="score" percentage color="#FFF"/>
      </div>
    </a>
  )
}

interface CompetenceBlendedProps {
  name: string;
  skillProgress: number;
  missionProgress: number;
  scoreProgress: number;
  url: string;
  backgroundColor : string;
}

export const CompetenceBlended: React.FC<CompetenceBlendedProps> = (props: CompetenceBlendedProps) =>  {
  return (
    <div className="competence">
        <div className="competence-content-overlay" style={{background: props.backgroundColor}}>
            <div className="overlay-text">Wurde noch nicht freigeschaltet.</div>
        </div>
        <div className="competence-content-container">
            <h3 className="competence__title">{props.name}</h3>
            <div className="competence__progress">
                <ProgressBar progress={props.skillProgress} max={25} text="skills" color="#FFF"/>
                <ProgressBar progress={props.missionProgress} max={25} text="missions" color="#FFF"/>
                <ProgressBar progress={props.scoreProgress} max={100} text="score" percentage color="#FFF"/>
            </div>
        </div>

    </div>
  )
}