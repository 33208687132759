import React from 'react';
import { ProgressBar } from '../../../components/progress-bar/ProgressBar';
import  Sidebar from '../../../components/sidebar/Sidebar';

import {Link, useHistory, useLocation, useParams} from 'react-router-dom';

import './kompetenznachweiss.scss';
import {keycloak} from "../../../api/login";
import Cookies from 'universal-cookie';
import {newEvents} from "../../test-data";
import AllEvents from "../events/Events";

const bagSVG = require('../../../img/bag.svg').default;
const profileSVG = require('../../../img/profile.svg').default;
const notificationSVG = require('../../../img/notification.svg').default;
const checkPNG = require('../../../img/check-icon.png').default;
const playPNG = require('../../../img/playbutton.png').default;
const ladyOverview = require('../../../img/lady-lesson-overview.png').default;
const deniseMerz = require('../../../img/denise.png').default;
const followButton = require('../../../img/follow-button.png').default;
const PADLOCK = require('../../../img/padlock.svg').default;
const attach = require('../../../img/attach.png').default;

const backText = "< Back"

interface Props {

}

export const KompetenznachweisPage: React.FC<Props> = (props: Props) =>  {

    const cookies = new Cookies();

    function setCookie() {
        cookies.set('course-1', true, { path: '/' });
        console.log(cookies.get('course-1')); // Pacman
    }

    return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="kompetenznachweiss-container">
      <Sidebar />
      <div className="container-right" style={{backgroundColor: '#F5F8FB', flexDirection: 'column', overflow: 'auto'}}>
        <TopBar mainText={`Lesson`} subText={`Budget Basic - level nu. basic`}/>
        <div className="main-container">
          <div className="container-up">
            <div className="top-bar-button">
              <a className="back-button-container" href="/lesson/allgemeinbildung/budgetbasic">
                <div className="back-button-text">{backText}</div>
              </a>
            </div>

            <div className="store-stack-container">
                <div className="store-content-stack-container">
                    <div className="store-content">
                    <div className="lesson-container">
                      <div className="div1"></div>
                      <div className="lesson-description">
                        <div style={{display: 'inline-flex'}}>
                          <div className="lesson-description__estimated-time">
                            <div style={{}}>Estimated time </div>
                            <div className="lesson-description__estimated-time-text"> 
                              <div>15min</div> 
                            </div>
                          </div>
                          <div className="lesson-description__type">
                            <div style={{marginBottom: '15px'}}>
                              Type
                            </div>
                            <div style={{fontSize: '14px'}}>
                              Einzelarbeit
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="lesson-description__assignment">Auftrag:</div>
                          <ul className="lesson-description__assignment-criteria">
                            <li className="lesson-description__assignment-description">Erstellen Sie eine Kurzdokumentation mit folgender Struktur:</li>
                            <li className="lesson-description__assignment-description" >Titelblatt: frei gestaltbar, Name und Klassenbezeichnung</li>
                            <li className="lesson-description__assignment-description" >Erster Teil: Budget für Auslandaufenthalt. Wo soll die Reise hingehen? Wie sieht Ihr Auslandaufenthalt aus?</li>
                            <li className="lesson-description__assignment-description" >Zweiter Teil: Persönliches Budget während der Lehre, wieviel können Sie während der Lehre sparen?</li>
                            <li className="lesson-description__assignment-description" >Dritter Teil: Persönliche Einschätzung bezüglich Realisierbarkeit, Stolpersteine, etc. Zeigen Sie auf, was Ihnen hilft, Ihr Sparziel zu erreichen, und was Sie auf jeden Fall vermeiden sollten.</li>
                            <li className="lesson-description__assignment-description" >Vierter Teil: Quellenangaben</li>
                          </ul>  
                        </div>
                          <a href="/lesson/allgemeinbildung/budgetbasic">
                              <button className="lesson-description__finish-lesson" onClick={setCookie}>
                                  <div className="lesson-description__finish-button">Finish lesson</div>
                              </button>
                          </a>
                        <div className="lesson-description__attach-img"><img src={attach}/></div>

                      </div>
                      
                      <div className="lesson-assignment">

                        <div className="lesson-assignment_video">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/m73BIC2cS-U" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                        </div>
                        <div className="lesson-assignment_disclaimer">
                          <div className="lesson-assignment__title">Budget - Kompetenznachweis</div>
                          <div className="lesson-assignment__text">Sie möchten sich nach der Lehre einen halbjährigen Sprachaufenthalt im Ausland gönnen. Legen Sie glaubhaft dar, wie Sie dieses Vorhaben mit Ihren finanziellen Mitteln und ohne sich zu verschulden umsetzen könnten. Berechnen Sie, wie hoch der monatliche Sparbetrag sein muss, den Sie in Ihrem Budget berücksichtigen müssen.</div>
                          <div className="lesson-assignment__subtitle">Anforderungskriterien Gesellschaft</div>
                          <ol className="lesson-assignment__list">
                            <li>Das persönliche Sparbudget ist detailliert und realistisch. Geben Sie also Auskunft über Ihre geplanten Einnahmen und Ausgaben während Ihrer ganzen Lehrzeit.</li>
                            <li>Das illustrierte und detaillierte Projektbudget gibt Auskunft über die geplanten Ausgaben und möglichen Einnahmen während Ihres halbjährigen Auslandaufenthaltes.</li>
                            <li>Die Zahlen, die für die Budgets verwendet werden, sind aktuell und die Quellen sind jeweils angegeben.</li>
                            <li>Die Darstellung der beiden Budgets ist übersichtlich, vollständig und klar.</li>
                          </ol>
                          <div className="lesson-assignment__subtitle">Anforderungskriterien Gesellschaft</div>
                          <ol className="lesson-assignment__list">
                            <li>Die persönliche Einschätzung enthält mindestens zehn Sätze.</li>
                            <li>Ihre Meinungen/Ansichten sind mit Argumenten gestützt oder mit Beispielen ergänzt.</li>
                            <li>Der Sprachgebrauch ist korrekt: Der Text ist im Präsens formuliert.</li>
                            <li>Die Einschätzung ist sachlich und verständlich. Sie zeigt, dass Sie sich mit der Sache auseinandergesetzt haben.</li>
                          </ol>
                        </div>
                        



                      </div>
                    </div>
                      

  
                        
                    </div>
                </div>
            </div>  
          </div> 
        </div>   
      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

    function logout() {
        keycloak.instance.logout();
    }

    const history = useHistory();
    const navigateToProfile = () => history.push('/myprofile');
    const navigateToStore = () => history.push('/store/all')

    return (
        <div className="top-bar">
            <div className="top-bar__text">
                <span className="top-bar__text--main">{props.mainText}</span>
                <span className="top-bar__text--sub">{props.subText}</span>
            </div>
            <div className="top-bar__nav">
                <div className="top-bar__nav--elem"><img src={bagSVG} onClick={navigateToStore} alt="bag"/></div>
                <div className="top-bar__nav--elem"><img src={profileSVG} onClick={navigateToProfile} alt="profile"/></div>
                <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
            </div>
        </div>
    );
}


interface SubCompetenceCourseOverviewNoBackgroundProps {
    name: string;
    scoreProgress: number;
  }
  
export const SubCompetenceCourseOverviewNoBackground: React.FC<SubCompetenceCourseOverviewNoBackgroundProps> = (props: SubCompetenceCourseOverviewNoBackgroundProps) =>  {
    return (
        <div className="course-competence">
        <h3 className="course-competence__title">{props.name}</h3>
        <div className="course-competence__progress">
            <ProgressBar progress={props.scoreProgress} max={100} percentage color="#E17FC2"/>
        </div>
        </div>
    );
}

interface CourseProps {
  titel: string;
  actualScore: number;
  scoreTitel: string;
  actualLessons: string;
  color: string;
}

export const Course: React.FC<CourseProps> = (props: CourseProps) => {
  return (
    <div className="course">
      <div className="course__titel">{props.titel}</div>
      <div className="course__right">
        <div className="course__progress">
          <div className="course__progress-title" style={{color: props.color}}>{props.scoreTitel}</div>
          <div className="course__lessons-done">{props.actualLessons}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>
  );
}


interface CourseProgressProps {
  actualScore: number;
  text: string;
  color: string;
}

export const CourseProgress: React.FC<CourseProgressProps> = (props: CourseProgressProps) => {
  return (
    <div className="course-progress-container">
      <div className="course-progress">
        <div className="course-progress__bar">
          <div className="course-progress__text" style={{color: props.color, fontWeight: 500}}>{props.text}</div>
          <ProgressBar progress={props.actualScore} max={100} percentage color={props.color}/>
        </div>
      </div>
    </div>    
  );
}

interface LessonCriteriaProps {
    text: string;
  }
  
export const LessonCriteria: React.FC<LessonCriteriaProps> = (props: LessonCriteriaProps) => {
    return (
        <div className="lesson-criteria">
            <div className="lesson-criteria__check"><img className="checkImg" src={checkPNG} alt="check"/></div>
            <div className="lesson-criteria__description">{props.text}</div>
        </div>
    );
}

interface ListLessonProps {
    title: string;
    duration: string;
}
  
export const ListLesson: React.FC<ListLessonProps> = (props: ListLessonProps) => {
    return (
        <div className="lesson">
            <div className="lesson__play"><img className="playImg" src={playPNG} alt="check"/></div>
            <div>
                <div className="lesson__title">{props.title}</div>
                <div className="lesson__duration">{props.duration}</div>
            </div>
        </div>
    );
}

interface AuthorProps {
    name: string;
    coursesFollowers: string;
    img: string;
}
  
export const Author: React.FC<AuthorProps> = (props: AuthorProps) => {
    return (
        <div className="author">
            <div className="author__profile-picture">
                <img className={props.img} src={props.img} alt={props.img}/>
            </div>
            <div className="author__description">
                <span className="name">{props.name}</span>
                <span className="courses-and-followers">{props.coursesFollowers}</span>
            </div>
            <div className="author__follow-button">
                <img className="author-follow-button" src={followButton} alt="follow"/>
            </div>
        </div>
    );
}





interface LessonProps {
    id: number;
    name: string;
    number: string;
    duration: string;
    locked?: boolean;
    minimize: number;
    linkTo: string;
    score?: number;
    completed?: boolean;
}



const MissionLesson: React.FC<LessonProps> = (props) => {
    const [animated, setAnimated] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);
  
    const location = useLocation();
  
    React.useEffect(() => {
      if (animated) {
        window.setTimeout(() => setAnimated(false), 500);
      }
    }, [animated]);
  
    React.useEffect(() => {
      if ((props.score && props.score >= 80) || props.completed) {
        setCompleted(true);
      }
    }, [props.completed, props.score]);
  
    return (
      <Link to={props.locked ? location.pathname : props.linkTo} className="lesson-link">
        <div className={`lesson ${props.minimize ? 'minimize' : ''} ${props.minimize === props.id ? 'highlight' : ''}`} title={props.name} onMouseDown={() => setAnimated(true)}>
          <div className={`${props.locked ? 'locked' : 'unlocked'} ${animated ? 'animated' : ''}`}>
            <img src={props.locked ? PADLOCK : playPNG} alt="lesson status"/>
          </div>
          <div className={completed ? 'strike' : ''}>
            {props.number}{'. ' + props.name}
          </div>
          <div>
            {Boolean(props.score && props.score !== 0) && `${props.score}%`}
          </div>
          {!props.minimize &&
            <div>
              {props.duration}
            </div>
          }
        </div>
      </Link>
    );
  }