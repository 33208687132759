import { keycloak } from "./login";
import { request } from "./request"

export interface APIJobProfile {
  uuid: string;
  title: string;
  description: string;
  skills: [{
    uuid: string;
    name: string;
    description: string;
    imageURL: string;
    competenceUUID: string;
    relevance: number;
  }];
}

export interface APIJobProfileProgress {
  uuid: string;
  totalProgress: number;
  skills: [{
    uuid: string,
    progress: number;
  }]
}

export const JobProfileAPI = {
  getProfiles(): Promise<APIJobProfile[]> {
    return request(`jobProfiles/${keycloak.user.sub}`);
  },

  getProfileProgress(id: string): Promise<APIJobProfileProgress> {
    return request(`jobProfile/progress/${id}/${keycloak.user.sub}`);
  }
}