import React from 'react';
import { Link } from 'react-router-dom';
import { Skill, TEST_MISSIONS } from '../../app/test-data';
import { Topbar } from '../../app/topbar/Topbar';
import { FAIcon } from '../fa-icon/FAIcon';
import { HeartButton } from '../heart-button/HeartButton';
import { calcMissionScore, getTimeString } from '../helpers';

import './profile-card.scss';

interface Props {
  img: string;
  title: string;
  author: string;
  skill: Skill;
  timestamp: Date;
  linkTo: string;
  missionId: number;
  isRecommendation?: boolean;
  nav?: React.Ref<typeof Topbar>;
}

export const ProfileCard: React.FC<Props> = (props) => {

  const onLinkClick = () => {
    if ((props.nav as any)?.current) {
      (props.nav as any).current.setNavElements([...(props.nav as any).current.getNavElements(), {linkTo: '/profile', pageName: 'Profile Overview'}]);
    }
  }
  
  const progress = calcMissionScore(props.missionId);

  let possibleSkillImprovment = 0;
  if (props.isRecommendation) {
    const currentMission = TEST_MISSIONS.find(mission => mission.id === props.missionId);
    if (currentMission && currentMission.skill) {
      const possibleScore = Math.round(100 / 3 * currentMission.level);
      possibleSkillImprovment = possibleScore - Number(currentMission.skill.score);
    }
  }

  return (
    <div className="profile-card"> 
      <img src={props.img} alt="mission"/>
      <div className="profile-info">
      <Link to={props.linkTo} onClick={onLinkClick}>
        <div className={`title ${progress >= 80 ? 'completed' : ''}`}>
          {props.title}    {progress >= 80 ? <FAIcon solid name="check" /> : ''}
        </div>
      </Link>
        <div className="author">{props.author}</div>
        <div className="skill"><span className="circle" style={{backgroundColor: props.skill.color}}/>
          {props.skill.name}
          {props.isRecommendation && ` | Improve your skill score by up to ${possibleSkillImprovment}%`}
        </div>
      </div>
      <div className="profile-action">
        <div className="button-container">
          <HeartButton size="20px"/>
        </div>
        <div className="timestamp">{getTimeString(props.timestamp)}</div>
      </div>
    </div>
  );
}