import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { APIJobProfile, APIJobProfileProgress, JobProfileAPI } from '../../api/jobProfile';
import { FAIcon } from '../../components/fa-icon/FAIcon';
import { calcMissionScore } from '../../components/helpers';
import { ProfileCard } from '../../components/profile-card/ProfileCard';
// import { ProgressBar } from '../../components/progress-bar/ProgressBar';
import { SkillProgressBar } from '../../components/skill-progress-bar/SkillProgressBar';
import { Competence, Level, Mission, Skill, TEST_COMPETENCES, TEST_MISSIONS, TEST_USERS } from '../test-data';
import { Topbar } from '../topbar/Topbar';

import './profile-overview.scss';

interface Props {
  nav: React.Ref<typeof Topbar>;
}

const MISSION_ENGINE = require('../../img/logistiker.jpg').default;

export const ProfileOverview: React.FC<Props> = (props) => {
  const [missions, setMissions] = React.useState<Mission[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [competences, setCompetences] = React.useState<Competence[]>([]);
  const [activeCompetence, setActiveCompetence] = React.useState<number>(0);
  const [openSkill, setOpenSkill] = React.useState<number>(0);
  const [showRecommendations, setShowRecommendations] = React.useState<boolean>(false);
  const [jobProfile, setJobProfile] = React.useState<APIJobProfile | null>(null);
  const [jobProfileProgress, setJobProfileProgress] = React.useState<APIJobProfileProgress | null>(null);

  React.useEffect(() => {
    const missions = TEST_MISSIONS.filter(mission => TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id))
    fillCompetences(TEST_COMPETENCES);
    fillMissionList(missions);

    loadJobProfile();
  }, []);

  React.useEffect(() => {
    let newMissions: Mission[] = [];

    newMissions = TEST_MISSIONS.filter(mission => TEST_USERS[Number(localStorage.getItem('userId')) - 1].booked.includes(mission.id));

    if (activeCompetence) {
      const competence = competences.find(competence => competence.id === activeCompetence);
      if (competence) {
        newMissions = newMissions.filter(mission => competence.skills.some(skill => skill.id === mission.skill.id));
      }
    }

    if (showRecommendations) newMissions = getRecommendedMissions(newMissions);

    setMissions([]);
    fillMissionList(newMissions);
  }, [activeCompetence, showRecommendations]);

  const fillMissionList = (missions: Mission[], index: number = 1) => {
    let tmp = [];
    if (index < missions.length) {
      for (let i = 0; i < index; i++) {
        tmp.push(missions[i]);
      }

      setMissions(tmp);
        window.setTimeout(() => fillMissionList(missions, index + 1), 75);
      }
  }

  const fillCompetences = (competences: Competence[]) => {
    setCompetences(competences);
  }

  const getRecommendedMissions = (startMissions: Mission[]) => {
    const skills: Skill[] = []; 
    startMissions.forEach(mission => {
      if (!skills.includes(mission.skill)) skills.push(mission.skill);
    });
    
    const recommendations = skills.map(skill => {
      const missionsWithSkill = startMissions
                                  .filter(mission => mission.skill.id === skill.id)
                                  .sort((a, b) => Math.round((a.score || 0) / 3 * a.level) - Math.round((b.score || 0) / 3 * b.level));

      const recommendationsForSkill: Mission[] = [];
      const highestMission = missionsWithSkill[missionsWithSkill.length - 1];
      let floorLevel = highestMission.score === 100 ? highestMission.level + 1 : highestMission.level;
      
      if (Number(highestMission.score) < 80 && Number(highestMission.score) !== 0) {
        recommendationsForSkill.push(highestMission); 
        floorLevel++;
      }

      if (!highestMission.score || highestMission.score === 0) {
        floorLevel = Level.Basic;
      }

      for (let i = floorLevel; i <= Level.Professional; i++) {
        const recommendationForLevel = missionsWithSkill
                                        .filter(mission => mission.level === i)
                                        .find(mission => {
                                          if (i === (recommendationsForSkill[0]?.level || floorLevel) && highestMission.score !== 0 && highestMission.level === floorLevel) return mission.lessons.some(lesson => lesson.score || lesson.score === 0);
                                          else return true;
                                        });
        if (recommendationForLevel) recommendationsForSkill.push(recommendationForLevel);
      }

      return recommendationsForSkill;
    });

    const finalRecommendations: Mission[] = [];
    recommendations.forEach(r => finalRecommendations.push(...r));

    return finalRecommendations;
  }

  const onSeachValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  const cancelSearch = () => {
    setSearchValue('');
  }

  const onCompetenceClick = (id: number, name: string) => {
    setActiveCompetence(id);
  }

  const onSkillClick = (id: number, name: string) => {
    setOpenSkill(openSkill === id ? 0 : id);
    setSearchValue(name);
  }

  const deselectCompetence = () => {
    setActiveCompetence(0);
    setSearchValue('');
  }

  const searchFilter = (mission: Mission): boolean => {
    let shouldDisplay = !searchValue;
    shouldDisplay = shouldDisplay || mission.name.toLowerCase().includes(searchValue.toLowerCase());
    shouldDisplay = shouldDisplay || mission.skill.name.toLowerCase().includes(searchValue.toLowerCase());
    shouldDisplay = shouldDisplay || `${mission.author.firstName.toLowerCase()} ${mission.author.lastName.toLowerCase()}`.includes(searchValue.toLowerCase());
    shouldDisplay = shouldDisplay || competences.filter(competence => competence.name.toLowerCase().includes(searchValue.toLowerCase())).some(competence => competence.skills.some(skill => skill.id === mission.skill.id));
    return shouldDisplay;
  }

  const loadJobProfile = async () => {
    const jobProfiles = await JobProfileAPI.getProfiles();
    setJobProfile(jobProfiles[0]);
    const progress = await JobProfileAPI.getProfileProgress('0e6f691d-6712-48e8-bdbc-3e8866ed0700')
    setJobProfileProgress(progress);
  }

  const profileProgress = Math.round(competences
                    .map(competence => Math.round(competence.skills
                                        .map(skill => skill.score || 0)
                                        .reduce((curr, acc) => acc + curr, 0) / competence.skills.length)
                    )
                    .reduce((curr, acc) => acc + curr, 0) / competences.length);

  const activeCompetenceObj = competences.find(competence => competence.id === activeCompetence);
  const activeCompetenceProgress = activeCompetenceObj ? Math.round(activeCompetenceObj.skills.map(skill => skill.score || 0).reduce((curr, acc) => acc + curr, 0) / activeCompetenceObj.skills.length) : 0;

  const filteredMissions = missions.filter(mission => searchFilter(mission));
  const showBasic = Boolean(filteredMissions.filter(mission => mission.level === Level.Basic).length);
  const showAdvanced = Boolean(filteredMissions.filter(mission => mission.level === Level.Advanced).length);
  const showProfessional = Boolean(filteredMissions.filter(mission => mission.level === Level.Professional).length);

  return (
    <section className="profile-overview">
      <div className="left">
        <div className="section">
          <h1>Profile Overview</h1>
          <div className="profile-details__wrapper">
            <img className="profile-details__wrapper--visual" src={jobProfile?.skills[0].imageURL} alt="mission"/>
            <div className="profile-details__wrapper--title">
              {jobProfile?.title}
            </div>
            <div className="profile-details__wrapper--text">
              {jobProfile?.description}
            </div>
          </div>
          <div className="profile-details__credits">
            <div>Finished 24 of your skills</div>
            <div>8 to go to complete this profile</div>
            {/* <div><ProgressBar color="#007bff" progress={60}/></div> */}
          </div>
        </div>
        <div className="section last">
          <div className="profile-progress__wrapper">
            <div className={`profile-progress competences ${activeCompetence ? 'hide' : 'show'}`}>
              <h1>
                <div className="header"><label>Profile Score</label><label>{jobProfileProgress?.totalProgress}%</label></div>
                {/* <ProgssBar color="#007bff" progress={jobProfileProgress?.totalProgress || 0}/> */}
              </h1>
              {competences.map(competence => {
                const progress = Math.round(competence.skills.map(skill => skill.score || 0).reduce((curr, acc) => acc + curr, 0) / competence.skills.length);
                return (
                  <div className="competence" onClick={() => onCompetenceClick(competence.id, competence.name)}>
                    <SkillProgressBar prefix="Competence" skill={competence.name} progress={progress} color={competence.color} />
                    <FAIcon name="angle-right" solid/>
                  </div>
                );
              })}
            </div>
            <div className={`profile-progress skills ${activeCompetence ? 'show' : 'hide'}`}>
              <div className="back-button" onClick={() => deselectCompetence()}>
                <FAIcon name="angle-left" solid/> Profile Score
              </div>
              <h1>
                <div className="header"><label>{activeCompetenceObj?.name} Score</label><label>{activeCompetenceProgress}%</label></div>
                {/* <ProgressBar color="#007bff" progress={activeCompetenceProgress}/> */}
              </h1>
              {competences.filter(competence => competence.id === activeCompetence).map(competence => competence.skills.map(skill => (
                <div className="skill" onClick={() => onSkillClick(skill.id, skill.name)}>
                  <FAIcon name="angle-down" solid style={{transform: `rotateX(${skill.id === openSkill ? 180 : 0}deg)`}}/>
                  <SkillProgressBar showLearnings={skill.id === openSkill} skill={skill.name} progress={skill.score || 0} color={skill.color} learnings={skill.learnings}/>
                </div>
              )))}
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <h1>Next Up Missions</h1>
        <div className={`competence-filter ${activeCompetence ? 'show' : 'hide'}`}>For Competence {activeCompetenceObj?.name}</div>
        <div className="search-field">
          <input type="text" value={searchValue} onChange={onSeachValueChange} />
          <button onClick={cancelSearch} >Cancel</button>
        </div>
        <div className="display-mode">
          <li onClick={() => setShowRecommendations(false)} className={!showRecommendations ? 'active' : ''}>All</li>
          <li onClick={() => setShowRecommendations(true)} className={showRecommendations ? 'active' : ''}>Recommended</li>
        </div>
        <Delimiter id={1} show={showBasic} name="Basic" tooltip="Basic course for beginners"/>
        <TransitionGroup>
          {filteredMissions
            .filter(mission => mission.level === Level.Basic)
            .sort((a, b) => calcMissionScore(a.id) - calcMissionScore(b.id))
            .map((mission) => (
              <CSSTransition key={mission.id} timeout={300} classNames="profile-card">
                <ProfileCard isRecommendation={showRecommendations} missionId={mission.id} nav={props.nav} linkTo={`/mission/${mission.id}`} title={mission.name} author={`${mission.author.firstName} ${mission.author.lastName}`} skill={mission.skill} img={mission.img} timestamp={mission.lastVisited}/>
              </CSSTransition>
          ))}
        </TransitionGroup>
        <Delimiter id={2} show={showAdvanced} name="Advanced" tooltip="Advanced practices building upon the basic building blocks"/>
        <TransitionGroup>
          {filteredMissions
            .filter(mission => mission.level === Level.Advanced)
            .sort((a, b) => calcMissionScore(a.id) - calcMissionScore(b.id))
            .map((mission) => (
              <CSSTransition key={mission.id} timeout={300} classNames="profile-card">
                <ProfileCard isRecommendation={showRecommendations} missionId={mission.id} nav={props.nav} linkTo={`/mission/${mission.id}`} title={mission.name} author={`${mission.author.firstName} ${mission.author.lastName}`} skill={mission.skill} img={mission.img} timestamp={mission.lastVisited}/>
              </CSSTransition>
          ))}
        </TransitionGroup>
        <Delimiter id={3} show={showProfessional} name="Professional" tooltip="Final step to complete a Skill"/>
        <TransitionGroup>
          {filteredMissions
            .filter(mission => mission.level === Level.Professional)
            .sort((a, b) => calcMissionScore(a.id) - calcMissionScore(b.id))
            .map((mission) => (
              <CSSTransition key={mission.id} timeout={300} classNames="profile-card">
                <ProfileCard isRecommendation={showRecommendations} missionId={mission.id} nav={props.nav} linkTo={`/mission/${mission.id}`} title={mission.name} author={`${mission.author.firstName} ${mission.author.lastName}`} skill={mission.skill} img={mission.img} timestamp={mission.lastVisited}/>
              </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </section>
  );
}

interface DelimiterProps {
  id: number;
  name: string,
  show: boolean;
  tooltip: string;
}

const Delimiter: React.FC<DelimiterProps> = (props) => {
  return (
    <div className={`delimiter ${props.show ? 'show' : 'hide'}`}>
      {props.name}
      <span data-tip data-for={props.id}>
        <FAIcon name="info-circle" solid/>
        <ReactTooltip id={props.id.toString()} type="dark" effect="solid">
          <span>{props.tooltip}</span>
        </ReactTooltip>
      </span>
    </div>
  )
}
