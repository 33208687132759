import React from 'react';
import { ProgressBar } from '../../components/progress-bar/ProgressBar';
import  Sidebar from '../../components/sidebar/Sidebar';

import './subcompetences.scss';
import {keycloak} from "../../api/login";
import {useHistory} from "react-router-dom";

const bagSVG = require('../../img/bag.svg').default;
const profileSVG = require('../../img/profile.svg').default;
const notificationSVG = require('../../img/notification.svg').default;

const nameCompetence1 = "Geld & \nKonsum"; nameCompetence1.replace(/\n/g, "<br />");
const nameCompetence2 = "Risiko & \nSicherheit"; nameCompetence2.replace(/\n/g, "<br />");
const nameCompetence3 = "Wohnen & \nZusammenleben"; nameCompetence3.replace(/\n/g, "<br />");
const nameCompetence4 = "Mitbestimmung \nim Staat"; nameCompetence4.replace(/\n/g, "<br />");
const nameCompetence5 = "Arbeitswelt & \nZukunft"; nameCompetence5.replace(/\n/g, "<br />");
const nameCompetence6 = "Gesundheit"; nameCompetence6.replace(/\n/g, "<br />");
const nameCompetence7 = "Ökologische \nHerausforderungen"; nameCompetence7.replace(/\n/g, "<br />");
const nameCompetence8 = "Kunst & \nKultur"; nameCompetence8.replace(/\n/g, "<br />");
const nameCompetence9 = "Sprache & \nKomunikation"; nameCompetence9.replace(/\n/g, "<br />");
const nameCompetence10 = "Start in die \nBerufswelt"; nameCompetence10.replace(/\n/g, "<br />");
const nameCompetence11 = "Steuern & \nRecht"; nameCompetence11.replace(/\n/g, "<br />");
const nameCompetence12 = "Wirtschaft"; nameCompetence12.replace(/\n/g, "<br />");

export const Subcompetences: React.FC = () =>  {
  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh'}} className="subcompetences-container">
      <Sidebar />
      <div style={{backgroundColor: '#F5F8FB', display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%'}}>
        <TopBar mainText={`Your Competences`} subText={`Overview of your main competences`}/>
        <h3 style={{margin: '30px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Allgemeinbildung</h3>
        <div className="subcompetences-container" style={{display: 'flex-box', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <Subcompetence name={nameCompetence1} maxProgress={25} missionProgress={0} subcompetenceUrl={"/subcompetences/geldundkonsum"} subcompetenceColor={"linear-gradient(93.28deg, #9A4DCB -13.74%, #E17FC2 104.34%)"}/>
            <SubCompetenceBlended name={nameCompetence2} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.83deg, #B9231F -13.78%, #FF9D00 114.98%)"}/>
            <SubCompetenceBlended name={nameCompetence3} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(110.66deg, #EB7F20 -5.94%, #FEB14B 104.3%)"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name={nameCompetence4} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(96.32deg, #04218D -22.56%, #5B4DCB 101.57%)"}/>
            <SubCompetenceBlended name={nameCompetence5} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(138.6deg, #E17FC2 -6.89%, #5E4ECD 88.61%, #508CD3 126.21%)"}/>
            <SubCompetenceBlended name={nameCompetence6} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"#597EF7"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name={nameCompetence7} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.28deg, #46AD5C -13.74%, #22A8C6 59.62%, #199CB9 106.21%)"}/>
            <SubCompetenceBlended name={nameCompetence8} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(160.54deg, #469DB2 13.05%, #4ABCBD 96.17%)"}/>
            <SubCompetenceBlended name={nameCompetence9} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.54deg, #46AD5C -13.76%, #FFE000 160.52%)"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name={nameCompetence10} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(110.66deg, #EB7F20 -5.94%, #FEB14B 104.3%)"}/>
            <SubCompetenceBlended name={nameCompetence11} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.23deg, #597EF7 -34.78%, #004CE5 148.24%)"}/>
            <SubCompetenceBlended name={nameCompetence12} maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(102.89deg, #FF00FF -29.11%, #FFE000 118.38%)"}/>
          </div>
        </div>

        <h3 style={{margin: '30px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Allgemeine Handlungskompetenzen</h3>
        <div className="subcompetences-container" style={{display: 'flex-box', marginTop: '20px', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Entgegennehmen von Gütern" maxProgress={13} missionProgress={0} subcompetenceUrl={"/allghandlungskompetenzen"} subcompetenceColor={"linear-gradient(93.28deg, #9A4DCB -13.74%, #E17FC2 104.34%)"}/>
            <SubCompetenceBlended name="Bewirtschaften von Gütern" maxProgress={8} missionProgress={0} subcompetenceUrl={"/bewirtschaften"} subcompetenceColor={"linear-gradient(93.83deg, #B9231F -13.78%, #FF9D00 114.98%)"}/>
            <SubCompetenceBlended name="Verteilen von Gütern" maxProgress={16} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.54deg, #46AD5C -13.76%, #FFE000 160.52%)"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Einhalten der Vorgaben zur Arbeitssicherheit" maxProgress={10} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(96.32deg, #04218D -22.56%, #5B4DCB 101.57%)"}/>
            <SubCompetenceBlended name="Optimieren von Qualität und Ressourceneffizienz" maxProgress={7} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(102.89deg, #FF00FF -29.11%, #FFE000 118.38%)"}/>
          </div>
        </div>

        <h3 style={{margin: '30px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Fachrichtungsspezifische Handlungskompetenzen</h3>
        <div className="subcompetences-container" style={{display: 'flex-box', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Entgegennehmung von Sendungen" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.28deg, #9A4DCB -13.74%, #E17FC2 104.34%)"}/>
            <SubCompetenceBlended name="Zustellung von Sendungen" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.83deg, #B9231F -13.78%, #FF9D00 114.98%)"}/>
            <SubCompetenceBlended name="Bewirtschaften von Lagern" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.54deg, #46AD5C -13.76%, #FFE000 160.52%)"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Bewegen im Bahnbetrieb" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(96.32deg, #04218D -22.56%, #5B4DCB 101.57%)"}/>
          </div>
        </div>

        <h3 style={{margin: '30px 50px 40px 50px', fontSize: 24, fontWeight: 600}}>Informatik</h3>
        <div className="subcompetences-container" style={{display: 'flex-box', flexDirection: 'column', width: '100%', justifyContent: 'space-between', padding: '0 50px 50px 50px'}}>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Entgegennehmung von Sendungen" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.28deg, #9A4DCB -13.74%, #E17FC2 104.34%)"}/>
            <SubCompetenceBlended name="Zustellung von Sendungen" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.83deg, #B9231F -13.78%, #FF9D00 114.98%)"}/>
            <SubCompetenceBlended name="Bewirtschaften von Lagern" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(93.54deg, #46AD5C -13.76%, #FFE000 160.52%)"}/>
          </div>
          <div className="subcompetences-row">
            <SubCompetenceBlended name="Bewegen im Bahnbetrieb" maxProgress={25} missionProgress={0} subcompetenceUrl={"/new"} subcompetenceColor={"linear-gradient(96.32deg, #04218D -22.56%, #5B4DCB 101.57%)"}/>
          </div>
        </div>

      </div>
    </div>
  )
}

interface TopBarProps {
  mainText: string;
  subText: string;
}

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {

  function logout() {
    keycloak.instance.logout();
  }

  const history = useHistory();
  const navigateToProfile = () => history.push('/myprofile');
  const navigateToStore = () => history.push('/store/lehrgaenge')

  return (
      <div className="top-bar">
        <div className="top-bar__text">
          <span className="top-bar__text--main">{props.mainText}</span>
          <span className="top-bar__text--sub">{props.subText}</span>
        </div>
        <div className="top-bar__nav">
          <div className="top-bar__nav--elem" onClick={navigateToStore} ><img src={bagSVG} alt="bag"/></div>
          <div className="top-bar__nav--elem" onClick={navigateToProfile}><img src={profileSVG} alt="profile"/></div>
          <div className="top-bar__nav--elem"><img src={notificationSVG} onClick={() => alert("Es ist kein Lehrer für Ring the Bell konfiguriert.")} alt="notification"/></div>
        </div>
      </div>
  );
}
interface CompeteceProps {
  name: string;
  missionProgress: number;
  maxProgress: number;
  subcompetenceUrl: string;
  subcompetenceColor: string;
}

export const Subcompetence: React.FC<CompeteceProps> = (props: CompeteceProps) =>  {
  return (
    <a className="subcompetence" href={props.subcompetenceUrl} style={{background: props.subcompetenceColor}}>
      <h3 className="subcompetence__title">{props.name}</h3>
      <div className="subcompetence__progress">
        <ProgressBar progress={props.missionProgress} max={props.maxProgress} text="missions" color="#FFF"/>
      </div>
    </a>
  )
}

interface SubCompetenceBlendedProps {
  name: string;
  missionProgress: number;
  maxProgress: number;
  subcompetenceUrl: string;
  subcompetenceColor: string;
}

export const SubCompetenceBlended: React.FC<SubCompetenceBlendedProps> = (props: SubCompetenceBlendedProps) =>  {
  return (
      <div className="subcompetence" style={{background: props.subcompetenceColor}}>
        <div className="subcompetence-content-overlay">
          <div className="overlay-text">Wurde noch nicht freigeschaltet.</div>
        </div>
        <div className="subcompetence-content-container">
          <h3 className="subcompetence__title">{props.name}</h3>
          <div className="subcompetence__progress">
            <ProgressBar progress={props.missionProgress} max={props.maxProgress} text="missions" color="#FFF"/>
          </div>
        </div>
      </div>
  )
}
