import React from 'react';

import './heart-button.scss';

export interface Props {
  /**
   * Control if the button is initially on or off
   */
  initialState?: boolean;
  /**
   * Custom size of the button (pass in WITH unit)
   */
  size?: string;
  /**
   * Custom onClick handler
   */
  onClick?: (state: boolean) => void;
}

export const HeartButton: React.FC<Props> = (props) => {
  const [active, setActive] = React.useState<boolean>(props.initialState || false);

  const onClick = () => {
    if (props.onClick) props.onClick(!active);
    setActive(!active);
  }

  return (
    <button style={{fontSize: props.size}} onClick={onClick} className="heart-button">
      &#9829;
      <div style={{fontSize: props.size}} className={`heart ${active ? 'active' : ''}`}>
        &#9829;
      </div>
    </button>
  );
}