import React from 'react';
import { Calendar as ExtCalendar, DayValue } from 'react-modern-calendar-datepicker';

// Default calendar styles
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './calendar.scss';

export interface Props {
  /**
   * Initially selected date
   */
  initialDate?: Date;
  /**
   * Called when a new date was selected
   */
  onChange?: (value: Date | null) => void;
}

export const Calendar: React.FC<Props> = (props) => {
  const [selectedDate, setSelectedDate] = React.useState<DayValue>(null);
 
  React.useEffect(() => {
    if (props.initialDate && selectedDate === null) {
      setSelectedDate({
        year: props.initialDate.getUTCFullYear(),
        month: props.initialDate.getUTCMonth() + 1,
        day: props.initialDate.getUTCDate()
      });
    }
  }, [props.initialDate]);

  const onDateChange = (value: DayValue) => {
    setSelectedDate(value);

    if (props.onChange) {
      props.onChange(value ? new Date(`${value.year}-${value.month}-${value.day}`) : null);
    }
  }

  return (
    <div className="event-calendar">
      <ExtCalendar
        value={selectedDate}
        onChange={onDateChange}
        colorPrimary="#4d9dff"
      />
    </div>
  );
}